import { useState, useEffect, useMemo } from "react";
import { Provider } from "react-redux";
import store from "store";
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import Sidenav from "examples/Sidenav";

import theme from "assets/theme";
import themeDark from "assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import PageLayout from "examples/LayoutContainers/PageLayout";
import "react-loading-skeleton/dist/skeleton.css";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  const [authentication , setAuthentication]= useState(userId && accessToken ? true : false)


  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route, index) => {
      if (route.route && route.protected) {
        if(authentication){
          return (
            <Route
              exact
              path={route.route}
              element={<DashboardLayout> {route.component} </DashboardLayout>}
              key={index}
            />
          );
        }else{
          return (
            <Route
              exact
              path={"*"}
              element={<Navigate to="/" />}
              key={index}
            />
          );
        }

      } else {
        return (
          <Route
            exact
            path={route.route}
            element={<PageLayout>{route.component}</PageLayout>}
            key={index}
          />
        );
      }
    });

  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/page_not_found" />} />
          </Routes>
        </ThemeProvider>
      </Provider>
    </>
  );
}
