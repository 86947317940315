import {
  GET_SETTINGS_START,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
  UPDATE_SETTINGS_START,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  settings: {
    data: {},
    loading: true,
    error: false,
  },
  updateSettings: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
};

const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS_START:
      return {
        ...state,
        settings: {
          ...state.settings,
          data: {},
          loading: true,
          error: false,
        },
      };

    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          data: action.data,
          loading: false,
          error: false,
        },
      };

    case GET_SETTINGS_FAILURE:
      return {
        ...state,
        settings: {
          ...state.settings,
          data: {},
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_SETTINGS_START:
      return {
        ...state,
        updateSettings: {
          ...state.updateSettings,
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        updateSettings: {
          ...state.updateSettings,
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: "Save",
        },
      };

    case UPDATE_SETTINGS_FAILURE:
      return {
        ...state,
        updateSettings: {
          ...state.updateSettings,
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    default:
      return state;
  }
};

export default SettingsReducer;