import {
  GET_WALLET_LIST_START,
  GET_WALLET_LIST_SUCCESS,
  GET_WALLET_LIST_FAILURE,
  VIEW_WALLET_START,
  VIEW_WALLET_SUCCESS,
  VIEW_WALLET_FAILURE,
  GET_TRANSACTION_HISTORY_START,
  GET_TRANSACTION_HISTORY_SUCCESS,
  GET_TRANSACTION_HISTORY_FAILURE,
  GET_RECENT_TRANSACTION_START,
  GET_RECENT_TRANSACTION_SUCCESS,
  GET_RECENT_TRANSACTION_FAILURE,
  GET_SINGLE_TRANSACTION_START,
  GET_SINGLE_TRANSACTION_SUCCESS,
  GET_SINGLE_TRANSACTION_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  WalletList: {
    data: {},
    loading: true,
    error: false,
  },
  viewWallet: {
    data: {},
    loading: true,
    error: false,
  },
  transactionHistory: {
    data: {},
    loading: true,
    error: false,
  },
  recentTransaction: {
    data: {},
    loading: true,
    error: false,
  },
  singleTransaction: {
    data: {},
    loading: true,
    error: false,
  },
};

const WalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WALLET_LIST_START:
      return {
        ...state,
        WalletList: {
          ...state.WalletList,
          data: {},
          loading: true,
          error: false,
        },
      };
    case GET_WALLET_LIST_SUCCESS:
      return {
        ...state,
        WalletList: {
          ...state.WalletList,
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case GET_WALLET_LIST_FAILURE:
      return {
        ...state,
        WalletList: {
          ...state.WalletList,
          data: {},
          loading: true,
          error: action.error,
        },
      };

    case VIEW_WALLET_START:
      return {
        ...state,
        viewWallet: {
          ...state.viewWallet,
          data: {},
          loading: true,
          error: false,
        },
      };
    case VIEW_WALLET_SUCCESS:
      return {
        ...state,
        viewWallet: {
          ...state.viewWallet,
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case VIEW_WALLET_FAILURE:
      return {
        ...state,
        viewWallet: {
          ...state.viewWallet,
          data: {},
          loading: true,
          error: action.error,
        },
      };

    case GET_TRANSACTION_HISTORY_START:
      return {
        ...state,
        transactionHistory: {
          ...state.transactionHistory,
          data: {},
          loading: true,
          error: false,
        },
      };
    case GET_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        transactionHistory: {
          ...state.transactionHistory,
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case GET_TRANSACTION_HISTORY_FAILURE:
      return {
        ...state,
        transactionHistory: {
          ...state.transactionHistory,
          data: {},
          loading: true,
          error: action.error,
        },
      };

    case GET_RECENT_TRANSACTION_START:
      return {
        ...state,
        recentTransaction: {
          ...state.recentTransaction,
          data: {},
          loading: true,
          error: false,
        },
      };
    case GET_RECENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        recentTransaction: {
          ...state.recentTransaction,
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case GET_RECENT_TRANSACTION_FAILURE:
      return {
        ...state,
        recentTransaction: {
          ...state.recentTransaction,
          data: {},
          loading: true,
          error: action.error,
        },
      };

    case GET_SINGLE_TRANSACTION_START:
      return {
        ...state,
        singleTransaction: {
          ...state.singleTransaction,
          data: {},
          loading: true,
          error: false,
        },
      };
    case GET_SINGLE_TRANSACTION_SUCCESS:
      return {
        ...state,
        singleTransaction: {
          ...state.singleTransaction,
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case GET_SINGLE_TRANSACTION_FAILURE:
      return {
        ...state,
        singleTransaction: {
          ...state.singleTransaction,
          data: {},
          loading: true,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default WalletReducer;
