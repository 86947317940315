// user actions

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const FETCH_OTHER_USER_DETAILS_START = "FETCH_OTHER_USER_DETAILS_START";
export const FETCH_OTHER_USER_DETAILS_SUCCESS = "FETCH_OTHER_USER_DETAILS_SUCCESS";
export const FETCH_OTHER_USER_DETAILS_FAILURE = "FETCH_OTHER_USER_DETAILS_FAILURE";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const USERNAME_VALIDATION_START = "USERNAME_VALIDATION_START";
export const USERNAME_VALIDATION_SUCCESS = "USERNAME_VALIDATION_SUCCESS";
export const USERNAME_VALIDATION_FAILURE = "USERNAME_VALIDATION_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";


// settings actions

export const GET_SETTINGS_START = "GET_SETTINGS_START"
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS"
export const GET_SETTINGS_FAILURE = "GET_SETTINGS_FAILURE"

export const UPDATE_SETTINGS_START = "UPDATE_SETTINGS_START"
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS"
export const UPDATE_SETTINGS_FAILURE = "UPDATE_SETTINGS_FAILURE"


// Looksup actions
export const ADD_STATIC_PAGES_START = "ADD_STATIC_PAGES_START"
export const ADD_STATIC_PAGES_SUCCESS = "ADD_STATIC_PAGES_SUCCESS"
export const ADD_STATIC_PAGES_FAILURE = "ADD_STATIC_PAGES_FAILURE"

export const UPDATE_STATIC_PAGES_START = "UPDATE_STATIC_PAGES_START"
export const UPDATE_STATIC_PAGES_SUCCESS = "UPDATE_STATIC_PAGES_SUCCESS"
export const UPDATE_STATIC_PAGES_FAILURE = "UPDATE_STATIC_PAGES_FAILURE"

export const LIST_STATIC_PAGES_START = "LIST_STATIC_PAGES_START"
export const LIST_STATIC_PAGES_SUCCESS = "LIST_STATIC_PAGES_SUCCESS"
export const LIST_STATIC_PAGES_FAILURE = "LIST_STATIC_PAGES_FAILURE"

export const VIEW_STATIC_PAGES_START = "VIEW_STATIC_PAGES_START"
export const VIEW_STATIC_PAGES_SUCCESS = "VIEW_STATIC_PAGES_SUCCESS"
export const VIEW_STATIC_PAGES_FAILURE = "VIEW_STATIC_PAGES_FAILURE"

export const APPROVE_DECLINE_STATIC_PAGES_START = "APPROVE_DECLINE_STATIC_PAGES_START"
export const APPROVE_DECLINE_STATIC_PAGES_SUCCESS = "APPROVE_DECLINE_STATIC_PAGES_SUCCESS"
export const APPROVE_DECLINE_STATIC_PAGES_FAILURE = "APPROVE_DECLINE_STATIC_PAGES_FAILURE"

export const DELETE_STATIC_PAGES_START = "DELETE_STATIC_PAGES_START"
export const DELETE_STATIC_PAGES_SUCCESS = "DELETE_STATIC_PAGES_SUCCESS"
export const DELETE_STATIC_PAGES_FAILURE = "DELETE_STATIC_PAGES_FAILURE"

// page actions

export const FETCH_SINGLE_PAGE_START = "FETCH_SINGLE_PAGE_START";
export const FETCH_SINGLE_PAGE_SUCCESS = "FETCH_SINGLE_PAGE_SUCCESS";
export const FETCH_SINGLE_PAGE_FAILURE = "FETCH_SINGLE_PAGE_FAILURE";


// wallet actions

export const GET_WALLET_LIST_START = "GET_WALLET_LIST_START";
export const GET_WALLET_LIST_SUCCESS = "GET_WALLET_LIST_SUCCESS";
export const GET_WALLET_LIST_FAILURE = "GET_WALLET_LIST_FAILURE";

export const VIEW_WALLET_START = "VIEW_WALLET_START";
export const VIEW_WALLET_SUCCESS = "VIEW_WALLET_SUCCESS";
export const VIEW_WALLET_FAILURE = "VIEW_WALLET_FAILURE";

export const GET_TRANSACTION_HISTORY_START = "GET_TRANSACTION_HISTORY_START";
export const GET_TRANSACTION_HISTORY_SUCCESS = "GET_TRANSACTION_HISTORY_SUCCESS";
export const GET_TRANSACTION_HISTORY_FAILURE = "GET_TRANSACTION_HISTORY_FAILURE";

export const GET_RECENT_TRANSACTION_START = "GET_RECENT_TRANSACTION_START";
export const GET_RECENT_TRANSACTION_SUCCESS = "GET_RECENT_TRANSACTION_SUCCESS";
export const GET_RECENT_TRANSACTION_FAILURE = "GET_RECENT_TRANSACTION_FAILURE";

export const GET_SINGLE_TRANSACTION_START = "GET_SINGLE_TRANSACTION_START";
export const GET_SINGLE_TRANSACTION_SUCCESS = "GET_SINGLE_TRANSACTION_SUCCESS";
export const GET_SINGLE_TRANSACTION_FAILURE = "GET_SINGLE_TRANSACTION_FAILURE";

export const FETCH_DASHBOARD_START = "FETCH_DASHBOARD_START";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";


// subscription actions

export const GET_SUBSCRIPTION_LIST_START = "GET_SUBSCRIPTION_LIST_START";
export const GET_SUBSCRIPTION_LIST_SUCCESS = "GET_SUBSCRIPTION_LIST_SUCCESS";
export const GET_SUBSCRIPTION_LIST_FAILURE = "GET_SUBSCRIPTION_LIST_FAILURE";

export const CREATE_UPDATE_SUBSCRIPTION_START = "CREATE_UPDATE_SUBSCRIPTION_START";
export const CREATE_UPDATE_SUBSCRIPTION_SUCCESS = "CREATE_UPDATE_SUBSCRIPTION_SUCCESS";
export const CREATE_UPDATE_SUBSCRIPTION_FAILURE = "CREATE_UPDATE_SUBSCRIPTION_FAILURE";

export const GET_SINGLE_SUBSCRIPTION_START = "GET_SINGLE_SUBSCRIPTION_START";
export const GET_SINGLE_SUBSCRIPTION_SUCCESS = "GET_SINGLE_SUBSCRIPTION_SUCCESS";
export const GET_SINGLE_SUBSCRIPTION_FAILURE = "GET_SINGLE_SUBSCRIPTION_FAILURE";

export const DELETE_SUBSCRIPTION_START = "DELETE_SUBSCRIPTION_START";
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS";
export const DELETE_SUBSCRIPTION_FAILURE = "DELETE_SUBSCRIPTION_FAILURE";

export const GET_SUBSCRIBERS_LIST_START = "GET_SUBSCRIBERS_LIST_START";
export const GET_SUBSCRIBERS_LIST_SUCCESS = "GET_SUBSCRIBERS_LIST_SUCCESS";
export const GET_SUBSCRIBERS_LIST_FAILURE = "GET_SUBSCRIBERS_LIST_FAILURE";