import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import BNB from "assets/images/walletcard/bnb.png";
import BUSDLOGO from "assets/images/walletcard/busd.png";
import BUSDXLOGO from "assets/images/walletcard/busdx.png";
import NFTCONTRACT from "assets/images/walletcard/nftcontract.png";

import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

function SubscriptionCard({ title, description, BUSD, BUSDX, nft_contract_address, id }) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={3}
        px={3}
        lineHeight={1}
        flexWrap="wrap"
        gap="1rem"
        height="100%"
      >
        <MDBox display="flex" gap="0.5rem" alignItems="center">
          <MDBox
            component="img"
            src={BUSDLOGO}
            alt="wallet"
            width="30px"
            display="block"
          />
          <MDTypography variant="body2" fontWeight="bold" color="text">
            BUSD :{" "}
          </MDTypography>
          <MDTypography variant="body2" fontWeight="regular" color="text">
            {BUSD}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" gap="0.5rem" alignItems="center">
          <MDBox
            component="img"
            src={BUSDXLOGO}
            alt="wallet"
            width="30px"
            display="block"
          />
          <MDTypography variant="body2" fontWeight="bold" color="text">
            BUSDX :{" "}
          </MDTypography>
          <MDTypography variant="body2" fontWeight="regular" color="text">
            {BUSDX}
          </MDTypography>
        </MDBox>
        
       
      </MDBox>
      
      <Divider />
      <MDBox textAlign="center" pt={0} px={3}>
        
        <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 1 }}>
          {title}
        </MDTypography>
        <MDTypography variant="body2" color="text" sx={{ mt: 1.5, mb: 1 }}>
          {description}
        </MDTypography>
      </MDBox>
      <Divider />
      <MDBox textAlign="left" pt={0} px={3}> 
        <MDTypography variant="body2" color="warning" sx={{ mt: 1.5, mb: 1 }}>
          {nft_contract_address ? nft_contract_address : "Contract: -"}
        </MDTypography>
      </MDBox>
      <Divider />
    
      <MDBox pb={3} px={3} lineHeight={1}>
        <MDBox display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" gap="1rem">
          <MDButton
            component={Link}
            to={`/view_subscription/${id}`}
            size={"small"}
            color="info"
            lineHeight={1}
            sx={{ cursor: "pointer"}}
          >
            View
          </MDButton>
          <MDButton
            component={Link}
            to={`/edit_subscription/${id}`}
            size={"small"}
            color="info"
            lineHeight={1}
            sx={{ cursor: "pointer"}}
          >
            Edit
          </MDButton>
          {/* <MDButton
            size={"small"}
            color="error"
            lineHeight={1}
            sx={{ cursor: "pointer"}}
          >
            Delete
          </MDButton> */}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Typechecking props for the BookingCard
SubscriptionCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  BUSD: PropTypes.string.isRequired,
  BUSDX: PropTypes.node.isRequired,
};

export default SubscriptionCard;
