import React, { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import profilePicture from "assets/images/team-3.jpg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DropdownButton from "./components/DropdownButton";
import { connect } from "react-redux";
import { 
  approveDeclineStaticPagesStart,
  deleteStaticPagesStart,
  listStaticPagesStart,
} from "store/actions/LooksupActions";

const ViewPages = (props) => {

  useEffect(() => {
    props.dispatch(listStaticPagesStart())
  },[])


  const handlePageDelete = (static_page_id) => {
    props.dispatch(deleteStaticPagesStart({static_page_id:static_page_id}))
  };

  const handlePageApprove = (static_page_id) => {
    props.dispatch(approveDeclineStaticPagesStart({static_page_id:static_page_id,status:true}))
  };

  const handlePageDecline = (static_page_id) => {
    props.dispatch(approveDeclineStaticPagesStart({static_page_id:static_page_id,status:false}))
  };
  
  return (
    <>
      <MDBox my={3}>
      {props.staticPagesList.loading ? (
        "Loading..."
      ) : (
      <Card>
        <TableContainer sx={{ maxHeight: "calc(100vh - 150px)", boxShadow: "none" }}>
          <Table>
            <TableHead>
              <MDBox
                component="tr"
                width="max-content"
                display="block"
                mb={1.5}
              >
                <MDTypography variant="h6" component="td">
                  View Pages
                </MDTypography>
              </MDBox>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <MDBox display="flex" flexDirection="column">
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      S.NO
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center">
                  <MDBox display="flex" flexDirection="column">
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Title
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center">
                  <MDBox display="flex" flexDirection="column">
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Updated
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center">
                  <MDBox display="flex" flexDirection="column">
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Status
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center">
                  <MDBox display="flex" flexDirection="column">
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      actions
                    </MDTypography>
                  </MDBox>
                </TableCell>
              </TableRow>

              {props.staticPagesList.data.map((pages, index) => (
                <>
                  <TableRow>
                  <TableCell
                      align="left"
                      sx={{ border: index === props.staticPagesList.data.length - 1 && 0 }}
                    >
                      <MDBox display="flex" flexDirection="column" ml={3}>
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                          >
                            {index + 1}
                          </MDTypography>
                        </MDBox>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: index === props.staticPagesList.data.length - 1 && 0 }}
                    >
                         <MDBox display="flex" flexDirection="column" ml={3}>
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                          >
                            {pages.title}
                          </MDTypography>
                        </MDBox>
                    </TableCell>
                    <TableCell align="center"  sx={{ border: index === props.staticPagesList.data.TableHeadlength - 1 && 0 }}>
                      <MDBox display="flex" flexDirection="column">
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {pages.updatedAt}
                        </MDTypography>
                      </MDBox>
                    </TableCell>
                    <TableCell align="center"  sx={{ border: index === props.staticPagesList.data.length - 1 && 0 }}>
                      <MDBadge
                        variant="contained"
                        color={pages.status ? "success" : "error"}
                        badgeContent={pages.status ? "Approved" : "Declined"}
                        container
                      />
                    </TableCell>
                    <TableCell align="center"  sx={{ border: index === props.staticPagesList.data.length - 1 && 0 }}>
                      <DropdownButton
                        buttonTitle={"actions"}
                        pages={pages}
                        decline={handlePageDecline}
                        approve={handlePageApprove}
                        pageDelete={handlePageDelete}
                      />
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      )}
    </MDBox>
    </>
  )
}

const mapStateToPros = (state) => ({
  staticPagesList: state.looksup.staticPagesList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default  connect(mapStateToPros, mapDispatchToProps)(ViewPages)