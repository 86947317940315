// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "pages/account/components/FormField";

// Data
import MDButton from "components/MDButton";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { updateSettingsStart } from "store/actions/SettingsActions";

import { useEffect, useState } from "react";
import { getSettingsStart } from "store/actions/SettingsActions";
import { connect } from "react-redux";
import { InputLabel, TextField } from "@mui/material";
import TransactionImage from "assets/images/transaction-history.png";

function SiteSettings(props) {
  const [siteSettingsData, setSiteSettingsData] = useState({
    site_name: "",
    // tag_name: "",
    site_logo:"",
    min_amount_transaction: 0,
  });

  const [logoPreview, setLogoPreview] = useState('');

  useEffect(() => {
    if (!props.settings.loading && props.settings.data.settings.length > 0) {
      let site_name,site_logo,min_amount_transaction = '';

      props.settings.data.settings.map((setting, key) => {
        if(setting.key == 'site_name') {
          site_name = setting.value;
        }
        if(setting.key == 'site_logo') {
          site_logo = setting.value;
        }
        if(setting.key == 'min_amount_transaction') {
          min_amount_transaction = setting.value;
        }
      });

      setSiteSettingsData({
        ...siteSettingsData,
        site_name: site_name,
        site_logo: site_logo,
        min_amount_transaction: min_amount_transaction,
      });
    }
  }, [props.settings.data]);

  const handleFileChange = (event, fileType) => {
    if (fileType == "site_logo") {
      if (event.currentTarget.type === "file") {
        let reader = new FileReader();
        let file = event.currentTarget.files[0];
        reader.onloadend = () => {
          setSiteSettingsData({
            ...siteSettingsData,
            site_logo:file
          });
          setLogoPreview(reader.result);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      }
    } else {
      // if (event.currentTarget.type === "file") {
      //   let reader = new FileReader();
      //   let file = event.currentTarget.files[0];
      //   reader.onloadend = () => {
      //     setSiteSettingsData({
      //       ...siteSettingsData,
      //       site_icon: {
      //         ...siteSettingsData.site_icon,
      //         previewImage: reader.result,
      //         file: file,
      //         file_type: file.type.match("image") ? "image" : "video",
      //       },
      //     });
      //   };

      //   if (file) {
      //     reader.readAsDataURL(file);
      //   }
      // }
    }
  };

  const handleInputChange = (event) => {
    setSiteSettingsData({
      ...siteSettingsData,
      [event.target.name]: event.target.value,
    });
  };

  const settingsSchema = Yup.object().shape({
    // facebook_link: Yup.string().required("Facebook Link is required *"),
    // twitter_link: Yup.string().required("Twitter Link is required *"),
  });

  const handleSubmit = (values) => {
    const newData = { ...values};
    props.dispatch(updateSettingsStart(newData));
  };

  return (
    <Card id="site-settings" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Site Settings</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Formik
          initialValues={{
            site_name: siteSettingsData.site_name,
            min_amount_transaction: siteSettingsData.min_amount_transaction,
            site_logo:siteSettingsData.site_logo,
          }}
          validationSchema={settingsSchema}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize
        >
          {({ touched, errors, isSubmitting, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="site_name"
                    type="text"
                    render={({ field, form }) => (
                      <TextField
                        type="text"
                        label="Site Name"
                        variant="outlined"
                        name="site_name"
                        fullWidth
                        placeholder="Site Name"
                        InputLabelProps={{ shrink: true }}
                        value={siteSettingsData.site_name}
                        error={Boolean(
                          form.errors.site_name && form.touched.site_name
                        )}
                        helperText={
                          form.touched.site_name && form.errors.site_name
                        }
                        onChange={(event) =>{
                            handleInputChange(event);
                            setFieldValue("site_name", event.target.value)
                          }
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="min_amount_transaction"
                    type="number"
                    render={({ field, form }) => (
                      <TextField
                        type="number"
                        label="Min Transaction Amount"
                        variant="outlined"
                        name="min_amount_transaction"
                        fullWidth
                        placeholder="Min Transaction Amount"
                        InputLabelProps={{ shrink: true }}
                        value={siteSettingsData.min_amount_transaction}
                        error={Boolean(
                          form.errors.min_amount_transaction && form.touched.min_amount_transaction
                        )}
                        helperText={
                          form.touched.min_amount_transaction && form.errors.min_amount_transaction
                        }
                        onChange={(event) =>{
                            handleInputChange(event);
                            setFieldValue("min_amount_transaction", event.target.value)
                          }
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="site_logo"
                    type="file"
                    render={({ field, form }) => (
                      <TextField
                        type="file"
                        label="Site Logo"
                        variant="outlined"
                        name="site_logo"
                        fullWidth
                        placeholder="Site Logo"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(
                          form.errors.site_logo && form.touched.site_logo
                        )}
                        helperText={
                          form.touched.site_logo && form.errors.site_logo
                        }
                        onChange={(event) =>{
                            handleFileChange(event,'site_logo');
                            // setFieldValue("site_logo", event.target.value)
                          }
                        }
                      />
                    )}
                  />
                  {logoPreview && (
                    <MDBox
                      component="img"
                      src={logoPreview}
                      width={"100%"}
                      height="auto"
                      maxHeight={"300px"}
                      sx={{ objectFit: "contain" }}
                      mt={3}
                    />
                  )}
                  {!logoPreview && siteSettingsData.site_logo && (
                    <MDBox
                      component="img"
                      src={siteSettingsData.site_logo}
                      width={"100%"}
                      height="auto"
                      maxHeight={"300px"}
                      sx={{ objectFit: "contain" }}
                      mt={3}
                    />
                  )}
                </Grid>
              </Grid>
              <MDBox
                mb={1}
                mt={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <MDButton 
                  variant="gradient" 
                  color="dark" 
                  size="medium"
                  type="submit"
                  disabled={props.updateSettings.buttonDisable}
                >
                  {props.updateSettings.loadingButtonContent != null
                      ? props.updateSettings.loadingButtonContent
                      : "Update settings"}
                </MDButton>
              </MDBox>
            </Form>
          )}
        </Formik>
      </MDBox>
    </Card>
  );
}

const mapStateToPros = (state) => ({
  settings: state.settings.settings,
  updateSettings:state.settings.updateSettings,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}


export default connect(mapStateToPros, mapDispatchToProps)(SiteSettings);
