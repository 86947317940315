import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "Environment";
import {
  ADD_STATIC_PAGES_START,
  UPDATE_STATIC_PAGES_START,
  LIST_STATIC_PAGES_START,
  VIEW_STATIC_PAGES_START,
  APPROVE_DECLINE_STATIC_PAGES_START,
  DELETE_STATIC_PAGES_START,
  FETCH_DASHBOARD_START
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "components/helper/ToastNotification";
import {
  addStaticPagesSuccess,
  addStaticPagesFailure,
  updateStaticPagesSuccess,
  updateStaticPagesFailure,
  listStaticPagesSuccess,
  listStaticPagesFailure,
  viewStaticPagesSuccess,
  viewStaticPagesFailure,
  approveDeclineStaticPagesSuccess,
  approveDeclineStaticPagesFailure,
  listStaticPagesStart,
  deleteStaticPagesSuccess,
  deleteStaticPagesFailure,
  fetchDashboardSuccess,
  fetchDashboardFailure,
} from "../actions/LooksupActions";

function* addStaticPagesAPI(action) {
  try {
    const response = yield api.postMethod("admin/static_pages/save", action.data);
    if (response.data.code == 200) {
      yield put(addStaticPagesSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      window.location.assign("/view_pages")
    } else {
      yield put(addStaticPagesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(addStaticPagesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateStaticPagesAPI(action) {
  try {
    const response = yield api.postMethod("admin/static_pages/save", action.data);
    if (response.data.success) {
      yield put(updateStaticPagesSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      yield put(listStaticPagesStart())
      window.location.assign("/view_pages")
    } else {
      yield put(updateStaticPagesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(updateStaticPagesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* listStaticPageAPI(action) {
  try {
    const response = yield api.postMethod("admin/static_pages/index", action.data);
    if (response.data.success) {
      yield put(listStaticPagesSuccess(response.data.data));
    } else {
      yield put(listStaticPagesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(listStaticPagesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* viewStaticPageAPI(action) {
  try {
    const response = yield api.postMethod("admin/static_pages/view", action.data);
    if (response.data.success) {
      yield put(viewStaticPagesSuccess(response.data.data));
    } else {
      yield put(viewStaticPagesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(viewStaticPagesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* approveDeclineStaticPageAPI(action) {
  try {
    const response = yield api.postMethod("admin/static_pages/status_update", action.data);
    if (response.data.success) {
      yield put(approveDeclineStaticPagesSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      yield put(listStaticPagesStart())
    } else {
      yield put(approveDeclineStaticPagesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(approveDeclineStaticPagesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* deleteStaticPageAPI(action) {
  try {
    const response = yield api.postMethod("admin/static_pages/delete", action.data);
    if (response.data.success) {
      yield put(deleteStaticPagesSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      yield put(listStaticPagesStart())
    } else {
      yield put(deleteStaticPagesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteStaticPagesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* dashboardAPI(action) {
  try {
    const response = yield api.postMethod("admin/dashboard", action.data);
    if (response.data.success) {
      yield put(fetchDashboardSuccess(response.data.data));
    } else {
      yield put(fetchDashboardFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchDashboardFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ADD_STATIC_PAGES_START, addStaticPagesAPI),
    yield takeLatest(UPDATE_STATIC_PAGES_START, updateStaticPagesAPI),
    yield takeLatest(LIST_STATIC_PAGES_START, listStaticPageAPI),
    yield takeLatest(VIEW_STATIC_PAGES_START, viewStaticPageAPI),
    yield takeLatest(APPROVE_DECLINE_STATIC_PAGES_START, approveDeclineStaticPageAPI),
    yield takeLatest(DELETE_STATIC_PAGES_START, deleteStaticPageAPI),
    yield takeLatest(FETCH_DASHBOARD_START, dashboardAPI),
  ]);
}
