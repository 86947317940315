import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "Environment";
import {
  GET_WALLET_LIST_START,
  VIEW_WALLET_START,
  GET_TRANSACTION_HISTORY_START,
  GET_RECENT_TRANSACTION_START,
  GET_SINGLE_TRANSACTION_START,
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "components/helper/ToastNotification";
import {
  getWalletListSuccess,
  getWalletListFailure,
  viewWalletSuccess,
  viewWalletFailure,
  getTransactionHistorySuccess,
  getTransactionHistoryFailure,
  getRecentTransactionsSuccess,
  getRecentTransactionsFailure,
  getSingleTransactionSuccess,
  getSingleTransactionFailure,
} from "../actions/WalletActions";

function* walletListAPI(action) {
  try {
    const response = yield api.postMethod("admin/wallets_index", action.data);
    if (response.data.code === 200) {
      yield put(getWalletListSuccess(response.data.data));
    } else {
      yield put(getWalletListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(getWalletListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* viewWalletAPI(action) {
  try {
    const response = yield api.postMethod("admin/wallets_view", action.data);
    if (response.data.success) {
      yield put(viewWalletSuccess(response.data.data));
    } else {
      yield put(viewWalletFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(viewWalletFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* transactionHistoryAPI(action) {
  try {
    const response = yield api.postMethod("admin/wallets_transactions", action.data);
    if (response.data.success) {
      yield put(getTransactionHistorySuccess(response.data.data));
    } else {
      yield put(getTransactionHistoryFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(getTransactionHistoryFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* recentTransactionAPI(action) {
  try {
    const response = yield api.postMethod("admin/wallets_transactions_recent", action.data);
    if (response.data.success) {
      yield put(getRecentTransactionsSuccess(response.data.data));
    } else {
      yield put(getRecentTransactionsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(getRecentTransactionsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* singleTransactionAPI(action) {
  try {
    const response = yield api.postMethod("admin/wallets_transactions_view", action.data);
    if (response.data.success) {
      yield put(getSingleTransactionSuccess(response.data.data));
    } else {
      yield put(getSingleTransactionFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(getSingleTransactionFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(GET_WALLET_LIST_START, walletListAPI),
    yield takeLatest(VIEW_WALLET_START, viewWalletAPI),
    yield takeLatest(GET_TRANSACTION_HISTORY_START, transactionHistoryAPI),
    yield takeLatest(GET_RECENT_TRANSACTION_START, recentTransactionAPI),
    yield takeLatest(GET_SINGLE_TRANSACTION_START, singleTransactionAPI),
  ]);
}
