import React, { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { connect } from "react-redux";
import profilePicture from "assets/images/team-3.jpg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DropdownButton from "./components/DropdownButton";
import { useEffect } from "react";
import { getWalletListStart } from "store/actions/WalletActions";
import TablePagination from "@mui/material/TablePagination";

const Wallets = (props) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.dispatch(getWalletListStart({skip:newPage*rowsPerPage}))
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { WalletList } = props;

  useEffect(() => {
    props.dispatch(getWalletListStart());
  }, []);
  console.log(props.WalletList);
  return (
    <MDBox my={3}>
    
        {WalletList.loading ? (
          "Loading..."
        ) : (
          <>
            <Card>
              <MDBox>
                <TableContainer
                  sx={{ maxHeight: "calc(100vh - 150px)", boxShadow: "none" }}
                >
                  <Table>
                    <TableHead>
                      <MDBox
                        component="tr"
                        width="max-content"
                        display="block"
                        mb={1.5}
                      >
                        <MDTypography variant="h6" component="td">
                          Connected Wallets
                        </MDTypography>
                      </MDBox>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                      <TableCell>
                          <MDBox display="flex" flexDirection="column">
                            <MDTypography
                              variant="h6"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              Name
                            </MDTypography>
                          </MDBox>
                        </TableCell>
                        <TableCell>
                          <MDBox display="flex" flexDirection="column">
                            <MDTypography
                              variant="h6"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              Wallet address
                            </MDTypography>
                          </MDBox>
                        </TableCell>
                        <TableCell align="center">
                          <MDBox display="flex" flexDirection="column">
                            <MDTypography
                              variant="h6"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              created
                            </MDTypography>
                          </MDBox>
                        </TableCell>
                        {/* <TableCell align="center">
                          <MDBox display="flex" flexDirection="column">
                            <MDTypography
                              variant="h6"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              balance
                            </MDTypography>
                          </MDBox>
                        </TableCell> */}
                        <TableCell align="center">
                          <MDBox display="flex" flexDirection="column">
                            <MDTypography
                              variant="h6"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              actions
                            </MDTypography>
                          </MDBox>
                        </TableCell>
                      </TableRow>

                      {WalletList.data.xwallets.map((wallet, index) => (
                        <>
                          <TableRow>
                          <TableCell
                              align="left"
                              sx={{ border: index === WalletList.data.length - 1 && 0 }}
                            >
                              
                              <MDBox display="flex" flexDirection="column">
                                  <MDTypography
                                    variant="button"
                                    fontWeight="regular"
                                    textTransform="capitalize"
                                  >
                                    {wallet.name}
                                  </MDTypography>
                                </MDBox>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ border: index === WalletList.data.length - 1 && 0 }}
                            >
                              {/* <MDBox
                                display="flex"
                                alignItems="center"
                                width="max-content"
                              >
                                <MDBox
                                  component="img"
                                  src={profilePicture}
                                  alt={""}
                                  width={"50px"}
                                  borderRadius={"50%"}
                                  height="auto"
                                />{" "}
                                
                              </MDBox> */}
                              <MDBox display="flex" flexDirection="column">
                                  <MDTypography
                                    variant="button"
                                    fontWeight="regular"
                                    textTransform="capitalize"
                                  >
                                    {wallet.wallet_address}
                                  </MDTypography>
                                </MDBox>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ border: index === WalletList.data.length - 1 && 0 }}
                            >
                              <MDBox display="flex" flexDirection="column">
                                <MDTypography
                                  variant="button"
                                  fontWeight="regular"
                                  textTransform="capitalize"
                                >
                                  {wallet.createdAt}
                                </MDTypography>
                              </MDBox>
                            </TableCell>
                            {/* <TableCell
                              align="center"
                              sx={{ border: index === walletData.length - 1 && 0 }}
                            >
                              <MDBadge
                                variant="contained"
                                color="success"
                                badgeContent={wallet.balance}
                                container
                              />
                            </TableCell> */}
                            <TableCell
                              align="center"
                              sx={{ border: index === WalletList.data.length - 1 && 0 }}
                            >
                              <DropdownButton buttonTitle={"actions"} id={wallet.xwallet_id}/>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <MDBox>
                  <TablePagination
                    rowsPerPageOptions={[12]}
                    component="div"
                    count={props.WalletList.data.total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ color: "#fff", fill: "#fff" }}
                  />
                </MDBox>
              </MDBox>
            </Card>
          </>
        )}
    
    </MDBox>
  );
};

const mapStateToPros = (state) => ({
  WalletList: state.wallet.WalletList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Wallets);
