import { all, fork } from "redux-saga/effects";

import UserSaga from './UserSaga';
import PageSaga from './PageSaga';
import SettingsSaga from './SettingsSaga';
import LooksupSaga from './LooksupSaga';
import WalletSaga from './WalletSaga';
import SubscriptionSaga from './SubscriptionSaga';

export default function* rootSaga() {
  yield all([fork(UserSaga)]);
  yield all([fork(PageSaga)]);
  yield all([fork(SettingsSaga)]);
  yield all([fork(LooksupSaga)]);
  yield all([fork(WalletSaga)]);
  yield all([fork(SubscriptionSaga)]);
}
