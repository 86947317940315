import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Link, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import WalletsList from "../components/WalletsList";
import BUSDLOGO from "assets/images/walletcard/busd.png";
import BUSDXLOGO from "assets/images/walletcard/busdx.png";
import { connect } from "react-redux";
import {
  getSingleSubscriptionStart,
  getSubscribersListStart,
} from "store/actions/SubscriptionActions";
import { useEffect } from "react";

function SingleSubscription(props) {
  const { subscription_id } = useParams();

  const { singleSubscription, subscribers } = props;

  useEffect(() => {
    props.dispatch(
      getSingleSubscriptionStart({ subscription_id: subscription_id })
    );
  }, []);

  return (
    <MDBox py={3}>
      <Card sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDBox mb={3}>
            <MDTypography variant="h4" fontWeight="medium">
              Subscription Details
            </MDTypography>
          </MDBox>
          <Divider />

          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} sx={{ mx: "auto" }}>
              <div className="single-sub-details-wrapper">
                <div className="single-sub-details">
                  {singleSubscription.loading ? (
                    <MDBox
                      mt={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <MDTypography variant="h4" fontWeight="bold">
                        loading
                      </MDTypography>
                    </MDBox>
                  ) : Object.keys(singleSubscription.data).length > 0 ? (
                    <>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox display="flex" gap="1rem" alignItems="center">
                            <MDTypography variant="h5" fontWeight="medium">
                              Title :{" "}
                            </MDTypography>
                            <MDTypography
                              variant="body2"
                              fontWeight="regular"
                              color="text"
                            >
                              {singleSubscription.data.title}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox display="flex" gap="1rem" alignItems="center">
                            <MDTypography variant="h5" fontWeight="meidum">
                              Plan type :{" "}
                            </MDTypography>
                            <MDTypography
                              variant="body2"
                              fontWeight="regular"
                              color="text"
                            >
                              {singleSubscription.data.plan_formatted}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox display="flex" gap="1rem" alignItems="center">
                            <MDTypography variant="h5" fontWeight="medium">
                              Description :{" "}
                            </MDTypography>
                            <MDTypography
                              variant="body2"
                              fontWeight="regular"
                              color="text"
                            >
                              {(singleSubscription.data.description != "" && singleSubscription.data.description != null) ? singleSubscription.data.description : "No description"}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox display="flex" gap="1rem" alignItems="center">
                            <MDBox
                              component="img"
                              src={BUSDLOGO}
                              alt="wallet"
                              width="30px"
                              display="block"
                            />
                            <MDTypography variant="h5" fontWeight="medium">
                              BUSD :{" "}
                            </MDTypography>
                            <MDTypography
                              variant="body2"
                              fontWeight="regular"
                              color="text"
                            >
                              {singleSubscription.data.busd_limit_formatted}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox display="flex" gap="1rem" alignItems="center">
                            <MDBox
                              component="img"
                              src={BUSDXLOGO}
                              alt="wallet"
                              width="30px"
                              display="block"
                            />
                            <MDTypography variant="h5" fontWeight="medium">
                              BUSDX :{" "}
                            </MDTypography>
                            <MDTypography
                              variant="body2"
                              fontWeight="regular"
                              color="text"
                            >
                              {singleSubscription.data.busdx_limit_formatted}
                            </MDTypography>
                          </MDBox>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                          <MDBox
                            display="flex"
                            alignItems="center"
                            gap="1rem"
                            flexWrap="wrap"
                          >
                            <MDButton
                              component={Link}
                              to={`/edit_subscription/${singleSubscription.data.subscription_id}`}
                              variant="gradient"
                              color="info"
                            >
                              Edit
                            </MDButton>

                            {/* <MDButton variant="gradient" color="error" ml={2}>
                              Delete
                            </MDButton> */}
                          </MDBox>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <MDBox
                      mt={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <MDTypography variant="h4" fontWeight="bold">
                        Something went wrong
                      </MDTypography>
                    </MDBox>
                  )}
                </div>
              </div>
            </Grid>

            <Grid item xs={12} lg={12}>
              <MDBox mt={3} mb={2}>
                <MDBox mb={1} ml={2}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Wallets list
                  </MDTypography>
                </MDBox>
                <WalletsList subscription_id={subscription_id} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}

const mapStateToPros = (state) => ({
  singleSubscription: state.subscription.singleSubscription,
  subscribers: state.subscription.subscribers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleSubscription);
