import {
  ADD_STATIC_PAGES_START,
  ADD_STATIC_PAGES_SUCCESS,
  ADD_STATIC_PAGES_FAILURE,
  UPDATE_STATIC_PAGES_START,
  UPDATE_STATIC_PAGES_SUCCESS,
  UPDATE_STATIC_PAGES_FAILURE,
  LIST_STATIC_PAGES_START,
  LIST_STATIC_PAGES_SUCCESS,
  LIST_STATIC_PAGES_FAILURE,
  VIEW_STATIC_PAGES_START,
  VIEW_STATIC_PAGES_SUCCESS,
  VIEW_STATIC_PAGES_FAILURE,
  APPROVE_DECLINE_STATIC_PAGES_START,
  APPROVE_DECLINE_STATIC_PAGES_SUCCESS,
  APPROVE_DECLINE_STATIC_PAGES_FAILURE,
  DELETE_STATIC_PAGES_START,
  DELETE_STATIC_PAGES_SUCCESS,
  DELETE_STATIC_PAGES_FAILURE,
  FETCH_DASHBOARD_START,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE
} from "./ActionConstant";


export function addStaticPagesStart(data) {
  return {
    type: ADD_STATIC_PAGES_START,
    data,
  };
}

export function addStaticPagesSuccess(data) {
  return {
    type: ADD_STATIC_PAGES_SUCCESS,
    data,
  };
}

export function addStaticPagesFailure(error) {
  return {
    type: ADD_STATIC_PAGES_FAILURE,
    error,
  };
}

export function updateStaticPagesStart(data) {
  return {
    type: UPDATE_STATIC_PAGES_START,
    data,
  };
}

export function updateStaticPagesSuccess(data) {
  return {
    type: UPDATE_STATIC_PAGES_SUCCESS,
    data,
  };
}

export function updateStaticPagesFailure(error) {
  return {
    type: UPDATE_STATIC_PAGES_FAILURE,
    error,
  };
}

export function listStaticPagesStart(data) {
  return {
    type: LIST_STATIC_PAGES_START,
    data,
  };
}

export function listStaticPagesSuccess(data) {
  return {
    type: LIST_STATIC_PAGES_SUCCESS,
    data,
  };
}

export function listStaticPagesFailure(error) {
  return {
    type: LIST_STATIC_PAGES_FAILURE,
    error,
  };
}

export function viewStaticPagesStart(data) {
  return {
    type: VIEW_STATIC_PAGES_START,
    data,
  };
}

export function viewStaticPagesSuccess(data) {
  return {
    type: VIEW_STATIC_PAGES_SUCCESS,
    data,
  };
}

export function viewStaticPagesFailure(error) {
  return {
    type: VIEW_STATIC_PAGES_FAILURE,
    error,
  };
}

export function approveDeclineStaticPagesStart(data) {
  return {
    type: APPROVE_DECLINE_STATIC_PAGES_START,
    data,
  };
}

export function approveDeclineStaticPagesSuccess(data) {
  return {
    type: APPROVE_DECLINE_STATIC_PAGES_SUCCESS,
    data,
  };
}

export function approveDeclineStaticPagesFailure(error) {
  return {
    type: APPROVE_DECLINE_STATIC_PAGES_FAILURE,
    error,
  };
}

export function deleteStaticPagesStart(data) {
  return {
    type: DELETE_STATIC_PAGES_START,
    data,
  };
}

export function deleteStaticPagesSuccess(data) {
  return {
    type: DELETE_STATIC_PAGES_SUCCESS,
    data,
  };
}

export function deleteStaticPagesFailure(error) {
  return {
    type: DELETE_STATIC_PAGES_FAILURE,
    error,
  };
}


export function fetchDashboardStart(data) {
  return {
    type: FETCH_DASHBOARD_START,
    data,
  };
}

export function fetchDashboardSuccess(data) {
  return {
    type: FETCH_DASHBOARD_SUCCESS,
    data,
  };
}

export function fetchDashboardFailure(error) {
  return {
    type: FETCH_DASHBOARD_FAILURE,
    error,
  };
}