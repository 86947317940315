import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Sidenav from "pages/account/profile/components/Sidenav";
import Header from "pages/account/profile/components/Header";
import BasicInfo from "pages/account/profile/components/BasicInfo";
import ChangePassword from "pages/account/profile/components/ChangePassword";
import DeleteAccount from "./components/DeleteAccount";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "store/actions/UserAction";
import { useEffect } from "react";

function Profile(props) {
  useEffect(() => {
    if (!props.profile.loading && Object.keys(props.profile.data).length == 0) {
      props.dispatch(fetchUserDetailsStart());
    }
  }, []);

  return (
    <>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                {!props.profile.loading && props.profile.data.length > 0 && (
                  <Grid item xs={12}>
                    <Header />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword />
                </Grid>
                {/* <Grid item xs={12}>
                  <DeleteAccount />
                </Grid> */}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Profile);
