import {
  GET_SUBSCRIPTION_LIST_START,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  GET_SUBSCRIPTION_LIST_FAILURE,
  CREATE_UPDATE_SUBSCRIPTION_START,
  CREATE_UPDATE_SUBSCRIPTION_SUCCESS,
  CREATE_UPDATE_SUBSCRIPTION_FAILURE,
  GET_SINGLE_SUBSCRIPTION_START,
  GET_SINGLE_SUBSCRIPTION_SUCCESS,
  GET_SINGLE_SUBSCRIPTION_FAILURE,
  DELETE_SUBSCRIPTION_START,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAILURE,
  GET_SUBSCRIBERS_LIST_START,
  GET_SUBSCRIBERS_LIST_SUCCESS,
  GET_SUBSCRIBERS_LIST_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  subscriptions: {
    data: {},
    loading: true,
    error: false,
  },
  createUpdateSubscription: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  singleSubscription: {
    data: {},
    loading: true,
    error: false,
  },
  deleteSubscription: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  subscribers: {
    data: {},
    loading: true,
    error: false,
  },
};

const PageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_LIST_START:
      return {
        ...state,
        subscriptions: {
          data: {},
          loading: true,
          error: false,
        },
        createUpdateSubscription: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case GET_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        subscriptions: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case GET_SUBSCRIPTION_LIST_FAILURE:
      return {
        ...state,
        subscriptions: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case CREATE_UPDATE_SUBSCRIPTION_START:
      return {
        ...state,
        createUpdateSubscription: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "updating...",
        },
      };
    case CREATE_UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        createUpdateSubscription: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CREATE_UPDATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        createUpdateSubscription: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case GET_SINGLE_SUBSCRIPTION_START:
      return {
        ...state,
        singleSubscription: {
          data: {},
          loading: true,
          error: false,
        },
        subscribers: {
          data: {},
          loading: true,
          error: false,
        },
        createUpdateSubscription: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case GET_SINGLE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        singleSubscription: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case GET_SINGLE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        singleSubscription: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

    case DELETE_SUBSCRIPTION_START:
      return {
        ...state,
        deleteSubscription: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "updating...",
        },
      };
    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        deleteSubscription: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case DELETE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        deleteSubscription: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
      case GET_SUBSCRIBERS_LIST_START:
      return {
        ...state,
        subscribers: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case GET_SUBSCRIBERS_LIST_SUCCESS:
      return {
        ...state,
        subscribers: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case GET_SUBSCRIBERS_LIST_FAILURE:
      return {
        ...state,
        subscribers: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default PageReducer;
