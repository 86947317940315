import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import DataTable from "./components/transactionTable";

import { connect } from "react-redux";
import { useEffect } from "react";

const TransactionHistory = (props) =>  {

  return (
    <MDBox my={3}>
      
        <Card>
          
          <DataTable entriesPerPage={false} canSearch={false} />
          
        </Card>
      
    </MDBox>
  );
}

const mapStateToPros = (state) => ({
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TransactionHistory);
