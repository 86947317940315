// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import { userRegisterStart } from "store/actions/UserAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import { connect } from "react-redux";

const Signup = (props) => {
  const handleSignup = (values) => {
    const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

    const newData = { ...values, user_timezone: timezone  , passwordConfirmation : values.confirm_password};

    delete newData.confirm_password

    props.dispatch(userRegisterStart(newData));
  };

  const registerSchema = Yup.object().shape({
    name: Yup.string().required("Name is required *"),
    // username: Yup.string().required("Username is required *"),
    // mobile: Yup.number()
    //   .required("Mobile Number is Required")
    //   .test("mobile", "Mobile Number is Invalid", (val) => {
    //     if (val) return val.toString().length >= 6;
    //   }),
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required *"),
    password: Yup.string()
      .required("Password is required *")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirm_password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .required("Confirm Password is required *")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={{
              name: "",
              // username: "",
              // mobile: "",
              email: "",
              password: "",
              confirm_password: "",
            }}
            validationSchema={registerSchema}
            onSubmit={(values) => handleSignup(values)}
          >
            {({ touched, errors, setFieldValue, isSubmitting }) => (
              <Form noValidate autoComplete="off">
                <MDBox mb={2}>
                  <Field
                    name="name"
                    type="text"
                    render={({ field, form }) => (
                      <TextField
                        type="text"
                        label="Name"
                        variant="standard"
                        fullWidth
                        placeholder="Name"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(form.errors.name && form.touched.name)}
                        helperText={form.touched.name && form.errors.name}
                        onChange={(event) =>
                          setFieldValue("name", event.target.value)
                        }
                      />
                    )}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <Field
                    type="email"
                    name="email"
                    render={({ field, form }) => (
                      <TextField
                        type="email"
                        label="Email"
                        variant="standard"
                        fullWidth
                        placeholder="john@example.com"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(form.errors.email && form.touched.email)}
                        helperText={form.touched.email && form.errors.email}
                        onChange={(event) =>
                          setFieldValue("email", event.target.value)
                        }
                      />
                    )}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <Field
                    name="password"
                    type="password"
                    render={({ field, form }) => (
                      <TextField
                        type="password"
                        label="Password"
                        variant="standard"
                        fullWidth
                        placeholder="*****"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(
                          form.errors.password && form.touched.password
                        )}
                        helperText={
                          form.touched.password && form.errors.password
                        }
                        onChange={(event) =>
                          setFieldValue("password", event.target.value)
                        }
                      />
                    )}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <Field
                    name="confirm_password"
                    type="password"
                    render={({ field, form }) => (
                      <TextField
                        type="password"
                        label="Confirm Password"
                        variant="standard"
                        fullWidth
                        placeholder="*****"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(
                          form.errors.confirm_password &&
                            form.touched.confirm_password
                        )}
                        helperText={
                          form.touched.confirm_password &&
                          form.errors.confirm_password
                        }
                        onChange={(event) =>
                          setFieldValue("confirm_password", event.target.value)
                        }
                      />
                    )}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                    disabled={props.signup.buttonDisable}
                  >
                    {props.signup.loadingButtonContent !== null
                      ? props.signup.loadingButtonContent
                      : "Register"}
                  </MDButton>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Already have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign In
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </Form>
            )}
          </Formik>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};

const mapStateToPros = (state) => ({
  signup: state.users.registerInputData,
  usernameAva: state.users.usernameAva,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Signup);
