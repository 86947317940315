import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  userLoginSuccess,
  userLoginFailure,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
  fetchOtherUserDetailsSuccess,
  fetchOtherUserDetailsFailure,
  fetchUserDetailsStart,
  userRegisterSuccess,
  userRegisterFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  deleteAccountSuccess,
  deleteAccountFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  usernameValidationSuccess,
  usernameValidationFailure,
  logoutSuccess,
  logoutFailure,
  changePasswordSuccess,
  changePasswordFailure
} from "../actions/UserAction";
import api from "Environment";

import {
  FETCH_USER_DETAILS_START,
  FETCH_OTHER_USER_DETAILS_START,
  LOGIN_START,
  UPDATE_USER_DETAILS_START,
  CHANGE_PASSWORD_START,
  REGISTER_START,
  FORGOT_PASSWORD_START,
  RESET_PASSWORD_START,
  DELETE_ACCOUNT_START,
  USERNAME_VALIDATION_START,
  LOGOUT_START
} from "../actions/ActionConstant";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "components/helper/ToastNotification";

function* getUserDetailsAPI() {
  try {
    const response = yield api.postMethod("admin/profile");

    if (response.data.code === 200) {
      yield put(fetchUserDetailsSuccess(response.data));
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("user_unique_id", response.data.data[0].admin_id);
      localStorage.setItem("accessToken", response.data.data[0].token);
      localStorage.setItem("name", response.data.data.name);
    } else {
      yield put(fetchUserDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchUserDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.response.data.error);
  }
}

function* userLoginAPI(action) {
  try {
    const response = yield api.postMethod("admin/login", action.data);
    if (response.data.code === 200) {
      if (response.data.code === 1001)
        window.location.assign("/register-verify");
      else {
        yield put(userLoginSuccess(response.data.data));
        localStorage.setItem("userId", response.data.data.admin_id);
        localStorage.setItem("accessToken", response.data.data.token);
        localStorage.setItem("user_picture", response.data.data.picture);
        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem(
          "user_unique_id",
          response.data.data.user_unique_id
        );
        yield put(fetchUserDetailsStart())
        setTimeout(() => {
          window.location.assign("/dashboard");
        },1000)
      }
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(userLoginFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(userLoginFailure(error));
    yield call(getErrorNotificationMessage , error.message);
  }
}

function* updateUserDetailsAPI(action) {
  try {
    const response = yield api.postMethod("admin/update_profile", action.data);
    if (response.data.code === 200) {
      yield put(updateUserDetailsSuccess(response.data.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      yield put(fetchUserDetailsStart())
    } else {
      yield put(updateUserDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(updateUserDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* getOtherUserDetailsAPI(action) {
  try {
    const response = yield api.postMethod("other_user_profile", action.data);

    if (response.data.success) {
      yield put(fetchOtherUserDetailsSuccess(response.data));
    } else {
      yield put(fetchOtherUserDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchOtherUserDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* userRegisterAPI() {
  try {
    const userData = yield select(
      (state) => state.users.registerInputData.data
    );
    const response = yield api.postMethod("admin/register", userData);

    console.log("response" , response)

    if (response.data.code === 200) {
      if (response.data.code === 1001)
        window.location.assign("/register/verify");
      else {
        yield put(userRegisterSuccess(response.data.data));
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("user_picture", response.data.data.picture);
        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem("userId", response.data.data.admin_id);
        localStorage.setItem("accessToken", response.data.data.token);
        yield put(fetchUserDetailsStart())
        yield call(getSuccessNotificationMessage, response.data.message);
        setTimeout(() => {
          window.location.assign("/dashboard");
        },1000)
      }
    } else {
      yield put(userRegisterFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(userRegisterFailure(error));
    console.log(error.message)
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* forgotPasswordAPI() {
  try {
    const userData = yield select(
      (state) => state.users.forgotPasswordInputData.data
    );

    if (
      !userData.email ||
      userData.email == undefined ||
      userData.email == null
    ) {
      yield call(getErrorNotificationMessage, "Please enter the email address");
    } else {
      const response = yield api.postMethod("admin/forgot_password", userData);
      if (response.data.success) {
        yield put(forgotPasswordSuccess(response.data.data.data));
        yield call(getSuccessNotificationMessage, response.data.data.message);
      } else {
        yield put(forgotPasswordFailure(response.data.error));
        yield call(getErrorNotificationMessage, response.data.data.error);
      }
    }
  } catch (error) {
    yield put(forgotPasswordFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* deleteAccountAPI() {
  try {
    const userData = yield select(
      (state) => state.users.deleteAccount.inputData
    );
    const response = yield api.postMethod("delete_account", userData);
    if (response.data.success) {
      yield put(deleteAccountSuccess(response.data.data));;
      yield call(getSuccessNotificationMessage, response.data.message);
      window.location.assign("/");
    } else {
      yield put(deleteAccountFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteAccountFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* resetPasswordAPI() {
  try {
    const inputData = yield select(
      (state) => state.users.resetPasswordInputData.inputData
    );
    const response = yield api.postMethod("reset_password", inputData);
    if (response.data.success) {
      yield put(resetPasswordSuccess(response.data.data));
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("user_cover", response.data.data.cover);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem("socket", true);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem(
        "is_document_verified",
        response.data.data.is_document_verified
      );
      localStorage.setItem(
        "is_verified_badge",
        response.data.data.is_verified_badge
          ? response.data.data.is_verified_badge
          : 0
      );
      localStorage.setItem("userId", response.data.data.user_id);
      localStorage.setItem("accessToken", response.data.data.token);
      yield call(getSuccessNotificationMessage, response.data.message);
      window.location.assign("/dashboard");
    } else {
      yield put(resetPasswordFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(resetPasswordFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* usernameValidationAPI() {
  try {
    const inputData = yield select(
      (state) => state.users.validationInputData.data
    );
    const response = yield api.postMethod("username_validation", inputData);
    if (response.data.success) {
      yield put(usernameValidationSuccess(response.data.success));
    } else {
      yield put(usernameValidationFailure(response.data.error));
    }
  } catch (error) {
    yield put(usernameValidationFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* logoutAPI(action) {
  try {
    const response = yield api.postMethod("admin/logout", action.data);
    if (response.data.data.success) {
      yield put(logoutSuccess(response.data.data.success));
      yield call(getSuccessNotificationMessage, response.data.data.message);
      window.location.assign("/")
    } else {
      yield put(logoutFailure(response.data.error));
    }
  } catch (error) {
    yield put(logoutFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}


function* changePasswordAPI(action) {
  try {
    const response = yield api.postMethod("admin/change_password", action.data);
    if (response.data.success) {
      yield put(changePasswordSuccess(response.data.data));
      localStorage.removeItem("user_unique_id")
      localStorage.removeItem("accessToken")
      yield call(getSuccessNotificationMessage, response.data.data.message);
      setTimeout(() => {
        window.location.assign("/");
      },1000)
    
    } else {
      yield put(changePasswordFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(changePasswordFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_USER_DETAILS_START, getUserDetailsAPI),
    yield takeLatest(FETCH_OTHER_USER_DETAILS_START, getOtherUserDetailsAPI),
    yield takeLatest(LOGIN_START, userLoginAPI),
    yield takeLatest(UPDATE_USER_DETAILS_START, updateUserDetailsAPI),
    yield takeLatest(REGISTER_START, userRegisterAPI),
    yield takeLatest(FORGOT_PASSWORD_START, forgotPasswordAPI),
    yield takeLatest(DELETE_ACCOUNT_START, deleteAccountAPI),
    yield takeLatest(RESET_PASSWORD_START, resetPasswordAPI),
    yield takeLatest(USERNAME_VALIDATION_START, usernameValidationAPI),
    yield takeLatest(LOGOUT_START, logoutAPI),
    yield takeLatest(CHANGE_PASSWORD_START, changePasswordAPI),
  ]);
}
