import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "Environment";
import { GET_SETTINGS_START , UPDATE_SETTINGS_START } from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "components/helper/ToastNotification";
import {
  getSettingsSuccess,
  getSettingsFailure,
  updateSettingsSuccess,
  updateSettingsFailure
} from "../actions/SettingsActions";

function* getSettingsAPI(action) {
  try {
    const response = yield api.postMethod(
      "/admin/settings_index", action.data
    );
    if (response.data.success) {
      yield put(getSettingsSuccess(response.data.data));
    } else {
      yield put(getSettingsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(getSettingsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateSettingsAPI(action) {
  try {
    const response = yield api.postMethod(
      "/admin/settings_update", action.data
    );
    if (response.data.success) {
      yield put(updateSettingsSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(updateSettingsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(updateSettingsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}


export default function* pageSaga() {
  yield all([yield takeLatest(GET_SETTINGS_START, getSettingsAPI)]);
  yield all([yield takeLatest(UPDATE_SETTINGS_START, updateSettingsAPI)]);
}
