import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import TransactionImage from "assets/images/transaction-history.png";
import MDBadge from "components/MDBadge";
import { connect } from "react-redux";
import { useEffect } from "react";
import {getRecentTransactionsStart} from "store/actions/WalletActions"
import DropdownButton from "./components/DropdownButton";

const RecentTransaction = (props) =>  {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.dispatch(getRecentTransactionsStart({skip:newPage*rowsPerPage}))
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    props.dispatch(getRecentTransactionsStart())
  }, [])

  return (
    <MDBox my={3}>
      {props.recentTransaction.loading ? (
        "Loading..."
      ) : (
        <Card>
          {/* <DataTable data={dataTableData} entriesPerPage={false} canSearch={false} /> */}
          <MDBox>
          <TableContainer
            sx={{ height: "calc(100vh - 200px)", boxShadow: "none" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <MDBox
                  component="tr"
                  width="max-content"
                  display="block"
                  mb={1.5}
                >
                  <MDTypography variant="h6" component="td">
                    Recent Transactions
                  </MDTypography>
                </MDBox>
              </TableHead>
              <TableBody>
                {props.recentTransaction.data.transactions
                  .map((transaction) => (
                    <>
                      <TableRow>
                        <TableCell>
                          <MDBox
                            display="flex"
                            flexDirection="row"
                            gap={"1rem"}
                            alignItems={"center"}
                          >
                            <MDBox
                              component="img"
                              src={TransactionImage}
                              alt={""}
                              width={"50px"}
                              height="auto"
                            />{" "}
                            <MDBox display="flex" flexDirection="column">
                              <MDTypography
                                variant="p"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                {transaction.xwallet_transaction_id}
                              </MDTypography>
                              <MDTypography
                                variant="p"
                                fontWeight="regular"
                                textTransform="capitalize"
                                color={"success"}
                              >
                                {transaction.amount_formatted}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        </TableCell>
                        <TableCell>
                          <MDBox display="flex" flexDirection="column">
                            {/* <MDTypography
                              variant="p"
                              fontWeight="regular"
                              textTransform="capitalize"
                            >
                              From :
                              <MDTypography
                                variant="p"
                                fontWeight="regular"
                                textTransform="capitalize"
                                color={"error"}
                              >
                                {" "}
                                {transaction.from}
                              </MDTypography>
                            </MDTypography> */}
                            <MDTypography
                              variant="p"
                              fontWeight="regular"
                              textTransform="capitalize"
                            >
                              To :{" "}
                              <MDTypography
                                variant="p"
                                fontWeight="regular"
                                textTransform="capitalize"
                                color={"error"}
                              >
                                {" "}
                                {transaction.to_address}
                              </MDTypography>
                            </MDTypography>
                          </MDBox>
                        </TableCell>
                        <TableCell>
                          <MDBox display="flex" flexDirection="column">
                            <MDBadge
                              variant="contained"
                              color="success"
                              badgeContent={transaction.balance}
                              container
                            />
                          </MDBox>
                        </TableCell>
                        <TableCell align="center">
                        <DropdownButton
                          buttonTitle={"actions"}
                          transaction_id={transaction.xwallet_transaction_id}
                        />
                      </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <MDBox>
            <TablePagination
              rowsPerPageOptions={[12]}
              component="div"
              count={props.recentTransaction.data.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ color: "#fff", fill: "#fff" }}
            />
          </MDBox>
        </MDBox>
        </Card>
      )}
    </MDBox>
  );
}

const mapStateToPros = (state) => ({
  recentTransaction: state.wallet.recentTransaction,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RecentTransaction);
