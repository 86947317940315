import {
  ADD_STATIC_PAGES_START,
  ADD_STATIC_PAGES_SUCCESS,
  ADD_STATIC_PAGES_FAILURE,
  UPDATE_STATIC_PAGES_START,
  UPDATE_STATIC_PAGES_SUCCESS,
  UPDATE_STATIC_PAGES_FAILURE,
  LIST_STATIC_PAGES_START,
  LIST_STATIC_PAGES_SUCCESS,
  LIST_STATIC_PAGES_FAILURE,
  VIEW_STATIC_PAGES_START,
  VIEW_STATIC_PAGES_SUCCESS,
  VIEW_STATIC_PAGES_FAILURE,
  APPROVE_DECLINE_STATIC_PAGES_START,
  APPROVE_DECLINE_STATIC_PAGES_SUCCESS,
  APPROVE_DECLINE_STATIC_PAGES_FAILURE,
  DELETE_STATIC_PAGES_START,
  DELETE_STATIC_PAGES_SUCCESS,
  DELETE_STATIC_PAGES_FAILURE,
  FETCH_DASHBOARD_START,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE
} from "../actions/ActionConstant";

const initialState = {
  addStaticPage: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  updateStaticPages: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  staticPagesList: {
    data: {},
    loading: true,
    error: false,
  },
  viewStaticPage: {
    data: {},
    loading: true,
    error: false,
  },
  approveDecline: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  deletePage: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  dashboard: {
    data: {},
    loading: true,
    error: false,
  },
};

const LooksupReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_STATIC_PAGES_START:
      return {
        ...state,
        addStaticPage: {
          ...state.addStaticPage,
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading... please wait",
        },
      };
    case ADD_STATIC_PAGES_SUCCESS:
      return {
        ...state,
        addStaticPage: {
          ...state.addStaticPage,
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case ADD_STATIC_PAGES_FAILURE:
      return {
        ...state,
        addStaticPage: {
          ...state.addStaticPage,
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case UPDATE_STATIC_PAGES_START:
      return {
        ...state,
        updateStaticPages: {
          ...state.updateStaticPages,
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading... please wait",
        },
      };
    case UPDATE_STATIC_PAGES_SUCCESS:
      return {
        ...state,
        updateStaticPages: {
          ...state.updateStaticPages,
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case UPDATE_STATIC_PAGES_FAILURE:
      return {
        ...state,
        updateStaticPages: {
          ...state.updateStaticPages,
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case LIST_STATIC_PAGES_START:
      return {
        ...state,
        staticPagesList: {
          ...state.staticPagesList,
          data: {},
          loading: true,
          error: false,
        },
      };
    case LIST_STATIC_PAGES_SUCCESS:
      return {
        ...state,
        staticPagesList: {
          ...state.staticPagesList,
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case LIST_STATIC_PAGES_FAILURE:
      return {
        ...state,
        staticPagesList: {
          ...state.staticPagesList,
          data: {},
          loading: true,
          error: action.error,
        },
      };

    case VIEW_STATIC_PAGES_START:
      return {
        ...state,
        viewStaticPage: {
          ...state.viewStaticPage,
          data: {},
          loading: true,
          error: false,
        },
      };
    case VIEW_STATIC_PAGES_SUCCESS:
      return {
        ...state,
        viewStaticPage: {
          ...state.viewStaticPage,
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case VIEW_STATIC_PAGES_FAILURE:
      return {
        ...state,
        viewStaticPage: {
          ...state.viewStaticPage,
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case APPROVE_DECLINE_STATIC_PAGES_START:
      return {
        ...state,
        approveDecline: {
          ...state.approveDecline,
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading... please wait",
        },
      };
    case APPROVE_DECLINE_STATIC_PAGES_SUCCESS:
      return {
        ...state,
        approveDecline: {
          ...state.approveDecline,
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case APPROVE_DECLINE_STATIC_PAGES_FAILURE:
      return {
        ...state,
        approveDecline: {
          ...state.approveDecline,
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case DELETE_STATIC_PAGES_START:
      return {
        ...state,
        deletePage: {
          ...state.deletePage,
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading... please wait",
        },
      };
    case DELETE_STATIC_PAGES_SUCCESS:
      return {
        ...state,
        deletePage: {
          ...state.deletePage,
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case DELETE_STATIC_PAGES_FAILURE:
      return {
        ...state,
        deletePage: {
          ...state.deletePage,
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_DASHBOARD_START:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          data: {},
          loading: true,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default LooksupReducer;
