// @mui material components
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

import { connect } from "react-redux";
import { forgotPasswordStart } from "store/actions/UserAction";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";

const ForgotPassword = (props) => {
  const handleForgotPassword = (value) => {
    props.dispatch(forgotPasswordStart(value));
  };

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required *"),
  });

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Forgot Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={3} pb={0} px={3}>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={forgotPasswordSchema}
            onSubmit={(values) => handleForgotPassword(values)}
          >
            {({ touched, errors, isSubmitting, setFieldValue }) => (
              <Form noValidate>
                <MDBox mb={2}>
                  <Field
                    type="email"
                    name="email"
                    render={({ field, form }) => (
                      <TextField
                        type="email"
                        label="Email"
                        variant="standard"
                        fullWidth
                        placeholder="john@example.com"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(form.errors.email && form.touched.email)}
                        helperText={form.touched.email && form.errors.email}
                        onChange={(event) =>
                          setFieldValue("email", event.target.value)
                        }
                      />
                    )}
                  />
                </MDBox>
                <MDBox mt={6} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    disabled={props.forgotPassword.buttonDisable}
                    type="submit"
                  >
                    {props.forgotPassword.loadingButtonContent !== null
                      ? props.forgotPassword.loadingButtonContent
                      : "Forgot Password"}
                  </MDButton>
                </MDBox>
              </Form>
            )}
          </Formik>
        </MDBox>
        <MDBox mt={3} mb={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Already have an account?{" "}
            <MDTypography
              component={Link}
              to="/"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign In
            </MDTypography>
          </MDTypography>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ForgotPassword);
