//pages 
import Dashboard from "pages/dashboard";
import Profile from "pages/account/profile";
import SiteSettings from "pages/account/siteSettings";
import Wallets from "pages/wallets";
import RecentTransactions from "pages/recentTransactions";
import TransactionHistory from "pages/transactionHistory";
import AddPage from "pages/staticPages/addPage";
import EditPage from "pages/staticPages/editPage";
import ViewPages from "pages/staticPages/viewPages";
import Signin from "pages/authentication/sign-in";
import Register from "pages/authentication/sign-up";
import ForgotPassword from "pages/authentication/forgot-password";
import ResetPassword from "pages/authentication/reset-password";
import PageNotFound from "pages/pageNotFound";
import Viewtransaction from "pages/recentTransactions/viewtransaction";
import ViewWallet from "pages/wallets/viewWallet";
import Viewsubscription from "pages/subscription/viewsubscription";
import EditSubscription from "pages/subscription/editsubscription";
import Createsubscription from "pages/subscription/createsubscription";
import SingleSubscription from "pages/subscription/singlesubscription";

const routes = [
  {
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    protected : true
  },
  {
    name: "profile",
    key: "profile",
    route: "/account/profile",
    component: <Profile />,
    protected : true
  },
  {
    name: "account_settings",
    key: "account_settings",
    route: "/account/settings",
    component: <SiteSettings />,
    protected : true
  },
  {
    name: "wallets",
    key: "wallets",
    route: "/wallets",
    component: <Wallets />,
    protected : true
  },
  {
    name: "Recent Transactions",
    key: "recent_transactions",
    route: "/recent_transactions",
    component: <RecentTransactions />,
    protected : true
  },
  {
    name: "Tranasaction History",
    key: "tranasaction_history",
    route: "/transaction_history",
    component: <TransactionHistory />,
    protected : true
  },
  {
    name: "Add_page",
    key: "add_page",
    route: "/add_page",
    component: <AddPage />,
    protected : true
  },
  {
    name: "edit_page",
    key: "edit_page",
    route: "/edit_page/:static_page_id",
    component: <EditPage />,
    protected : true
  },
  {
    name: "view_pages",
    key: "view_pages",
    route: "/view_pages",
    component: <ViewPages />,
    protected : true
  },
  {
    name: "settings",
    key: "settings",
    route: "/settings",
    component: <SiteSettings />,
    protected : true
  },
  {
    name: "my_profile",
    key: "my_profile",
    route: "/my_profile",
    component: <Profile />,
    protected : true
  },
  {
    name: "login",
    key: "login",
    route: "/",
    component: <Signin />,
    protected : false
  },
  {
    name: "register",
    key: "register",
    route: "/register",
    component: <Register />,
    protected : false
  },
  {
    name: "reset_password",
    key: "reset_password",
    route: "/forgot_password",
    component: <ForgotPassword />,
    protected : false
  },
  {
    name: "reset_password",
    key: "reset_password",
    route: "/reset_password/:reset_token",
    component: <ResetPassword />,
    protected : false
  },
  {
    name: "page_not_found",
    key: "page_not_found",
    route: "*",
    component: <PageNotFound />,
    protected : false
  },
  {
    name: "View Transactions",
    key: "view_transactions",
    route: "/view_transaction/:transaction_id",
    component: <Viewtransaction />,
    protected : true
  },
  {
    name: "View Transactions",
    key: "view_transactions",
    route: "/view_wallet/:xwallet_id",
    component: <ViewWallet />,
    protected : true
  },
  {
    name: "View Subscriptions",
    key: "view_subscriptions_list",
    route: "/view_subscriptions_list",
    component: <Viewsubscription />,
    protected : true
  },
  {
    name: "edit Subscriptions",
    key: "edit_subscriptions",
    route: "/edit_subscription/:subscription_id",
    component: <EditSubscription />,
    protected : true
  },
  // {
  //   name: "create Subscriptions",
  //   key: "create_subscription",
  //   route: "/create_subscription",
  //   component: <Createsubscription />,
  //   protected : true
  // },
  {
    name: "view subscription",
    key: "view_subscription",
    route: "/view_subscription/:subscription_id",
    component: <SingleSubscription />,
    protected : true
  },
];

export default routes;
