import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import { connect } from "react-redux";
import { Icon } from "@mui/material";

function Header(props) {
  const [visible, setVisible] = useState(true);

  const handleSetVisible = () => setVisible(!visible);

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {/* <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" /> */}
            <Icon sx={{fontSize : "70px !important" , color : "#fff !important"}}>account_circle</Icon>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {props.profile.data[0].name}
              </MDTypography>
              {/* <MDTypography variant="button" color="text" fontWeight="medium">
                CEO / Co-Founder
              </MDTypography> */}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Header);
