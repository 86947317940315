import React from "react";
import { Box } from "@mui/system";
import MDButton from "components/MDButton";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";
import MDTypography from "components/MDTypography";

const DropdownButton = ({ buttonTitle,pages,decline,approve,pageDelete,props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <MDBox mx="auto">
          <MDButton
            variant="gradient"
            color="error"
            sx={{ height: "100%" }}
            onClick={handleClick}
          >
            {buttonTitle}{" "}
            {anchorEl == null ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )}
          </MDButton>
        </MDBox>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "#202940",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem component={Link} to={"/edit_page/" + pages.static_page_id}>
          Edit
        </MenuItem>
        {pages.status ? 
          <MenuItem onClick={() => decline(pages.static_page_id)}>Decline</MenuItem>
        : 
          <MenuItem onClick={() => approve(pages.static_page_id)}>Approve</MenuItem>
        }
        <MenuItem onClick={() => pageDelete(pages.static_page_id)}>Delete</MenuItem>
      </Menu>
    </>
  );
};

export default DropdownButton;