import {
  GET_SETTINGS_START,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
  UPDATE_SETTINGS_START,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE
} from "./ActionConstant";


export function getSettingsStart(data) {
  return {
    type: GET_SETTINGS_START,
    data,
  };
}

export function getSettingsSuccess(data) {
  return {
    type: GET_SETTINGS_SUCCESS,
    data,
  };
}

export function getSettingsFailure(error) {
  return {
    type: GET_SETTINGS_FAILURE,
    error,
  };
}

export function updateSettingsStart(data) {
  return {
    type: UPDATE_SETTINGS_START,
    data,
  };
}

export function updateSettingsSuccess(data) {
  return {
    type: UPDATE_SETTINGS_SUCCESS,
    data,
  };
}

export function updateSettingsFailure(error) {
  return {
    type: UPDATE_SETTINGS_FAILURE,
    error,
  };
}

