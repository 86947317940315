// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { updateSettingsStart } from "store/actions/SettingsActions";
import { InputLabel, TextField } from "@mui/material";

function MobileSettings(props) {
  const [socialSettingsData, setSocialSettingsData] = useState({
    facebook_link: "",
    twitter_link: "",
    linkedin_link: "",
    instagram_link: "",
    telegram_link: "",
    youtube_link: "",
    ga_scripts: "",
    head_scripts: "",
    body_scripts: "",
  });

  useEffect(() => {
    if (!props.settings.loading && props.settings.data.settings.length > 0) {
      let facebook_link,twitter_link,linkedin_link,instagram_link,telegram_link,youtube_link,ga_scripts,head_scripts,body_scripts = '';

      props.settings.data.settings.map((setting, key) => {
        if(setting.key == 'facebook_link') {
          facebook_link = setting.value;
        }
        if(setting.key == 'twitter_link') {
          twitter_link = setting.value;
        }
        if(setting.key == 'linkedin_link') {
          linkedin_link = setting.value;
        }
        if(setting.key == 'instagram_link') {
          instagram_link = setting.value;
        }
        if(setting.key == 'telegram_link') {
          telegram_link = setting.value;
        }
        if(setting.key == 'youtube_link') {
          youtube_link = setting.value;
        }
        if(setting.key == 'ga_scripts') {
          ga_scripts = setting.value;
        }
        if(setting.key == 'head_scripts') {
          head_scripts = setting.value;
        }
        if(setting.key == 'head_scripts') {
          body_scripts = setting.value;
        }
      });

      setSocialSettingsData({
        ...socialSettingsData,
        facebook_link: facebook_link,
        twitter_link: twitter_link,
        linkedin_link: linkedin_link,
        instagram_link: instagram_link,
        telegram_link: telegram_link,
        youtube_link: youtube_link,
        ga_scripts: ga_scripts,
        head_scripts: head_scripts,
        body_scripts: body_scripts,
      });
    }
  }, [props.settings.data]);

  const handleInputChange = (event) => {
    setSocialSettingsData({
      ...socialSettingsData,
      [event.target.name]: event.target.value,
    });
  };

  const settingsSchema = Yup.object().shape({
    // facebook_link: Yup.string().required("Facebook Link is required *"),
    // twitter_link: Yup.string().required("Twitter Link is required *"),
  });

  const handleSubmit = (values) => {
    const newData = { ...values};
    props.dispatch(updateSettingsStart(newData));
  };
  return (
    <Card id="mobile-settings" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Social Settings</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Formik
          initialValues={{
            facebook_link: socialSettingsData.facebook_link,
            twitter_link: socialSettingsData.twitter_link,
            linkedin_link: socialSettingsData.linkedin_link,
            instagram_link: socialSettingsData.instagram_link,
            telegram_link: socialSettingsData.telegram_link,
            youtube_link: socialSettingsData.youtube_link,
            ga_scripts: socialSettingsData.ga_scripts,
            head_scripts: socialSettingsData.head_scripts,
            body_scripts: socialSettingsData.body_scripts,
          }}
          validationSchema={settingsSchema}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize
        >
          {({ touched, errors, isSubmitting, setFieldValue }) => (
            <Form noValidate autoComplete="off">

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    name="facebook_link"
                    type="text"
                    render={({ field, form }) => (
                      <TextField
                        type="text"
                        label="Facebook Link"
                        variant="outlined"
                        name="facebook_link"
                        fullWidth
                        placeholder="Facebook Link"
                        InputLabelProps={{ shrink: true }}
                        value={socialSettingsData.facebook_link}
                        error={Boolean(
                          form.errors.facebook_link && form.touched.facebook_link
                        )}
                        helperText={
                          form.touched.facebook_link && form.errors.facebook_link
                        }
                        onChange={(event) =>{
                            handleInputChange(event);
                            setFieldValue("facebook_link", event.target.value)
                          }
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="twitter_link"
                    type="text"
                    render={({ field, form }) => (
                      <TextField
                        type="text"
                        label="Twitter Link"
                        variant="outlined"
                        name="twitter_link"
                        fullWidth
                        placeholder="Twitter Link"
                        InputLabelProps={{ shrink: true }}
                        value={socialSettingsData.twitter_link}
                        error={Boolean(
                          form.errors.twitter_link && form.touched.twitter_link
                        )}
                        helperText={
                          form.touched.twitter_link && form.errors.twitter_link
                        }
                        onChange={(event) =>{
                            handleInputChange(event);
                            setFieldValue("twitter_link", event.target.value)
                          }
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="linkedin_link"
                    type="text"
                    render={({ field, form }) => (
                      <TextField
                        type="text"
                        label="Linkedin Link"
                        variant="outlined"
                        name="linkedin_link"
                        fullWidth
                        placeholder="Linkedin Link"
                        InputLabelProps={{ shrink: true }}
                        value={socialSettingsData.linkedin_link}
                        error={Boolean(
                          form.errors.linkedin_link && form.touched.linkedin_link
                        )}
                        helperText={
                          form.touched.linkedin_link && form.errors.linkedin_link
                        }
                        onChange={(event) =>{
                            handleInputChange(event);
                            setFieldValue("linkedin_link", event.target.value)
                          }
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="instagram_link"
                    type="text"
                    render={({ field, form }) => (
                      <TextField
                        type="text"
                        label="Instagram Link"
                        variant="outlined"
                        name="instagram_link"
                        fullWidth
                        placeholder="Instagram Link"
                        InputLabelProps={{ shrink: true }}
                        value={socialSettingsData.instagram_link}
                        error={Boolean(
                          form.errors.instagram_link && form.touched.instagram_link
                        )}
                        helperText={
                          form.touched.instagram_link && form.errors.instagram_link
                        }
                        onChange={(event) =>{
                            handleInputChange(event);
                            setFieldValue("instagram_link", event.target.value)
                          }
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="telegram_link"
                    type="text"
                    render={({ field, form }) => (
                      <TextField
                        type="text"
                        label="Telegram Link"
                        variant="outlined"
                        name="telegram_link"
                        fullWidth
                        placeholder="Telegram Link"
                        InputLabelProps={{ shrink: true }}
                        value={socialSettingsData.telegram_link}
                        error={Boolean(
                          form.errors.telegram_link && form.touched.telegram_link
                        )}
                        helperText={
                          form.touched.telegram_link && form.errors.telegram_link
                        }
                        onChange={(event) =>{
                            handleInputChange(event);
                            setFieldValue("telegram_link", event.target.value)
                          }
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="youtube_link"
                    type="text"
                    render={({ field, form }) => (
                      <TextField
                        type="text"
                        label="Youtube Link"
                        variant="outlined"
                        name="youtube_link"
                        fullWidth
                        placeholder="Youtube Link"
                        InputLabelProps={{ shrink: true }}
                        value={socialSettingsData.youtube_link}
                        error={Boolean(
                          form.errors.youtube_link && form.touched.youtube_link
                        )}
                        helperText={
                          form.touched.youtube_link && form.errors.youtube_link
                        }
                        onChange={(event) =>{
                            handleInputChange(event);
                            setFieldValue("youtube_link", event.target.value)
                          }
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="ga_scripts"
                    type="text"
                    render={({ field, form }) => (
                      <TextField
                        type="text"
                        label="GA Scripts"
                        variant="outlined"
                        name="ga_scripts"
                        fullWidth
                        placeholder="GA Scripts"
                        InputLabelProps={{ shrink: true }}
                        value={socialSettingsData.ga_scripts}
                        error={Boolean(
                          form.errors.ga_scripts && form.touched.ga_scripts
                        )}
                        helperText={
                          form.touched.ga_scripts && form.errors.ga_scripts
                        }
                        onChange={(event) =>{
                            handleInputChange(event);
                            setFieldValue("ga_scripts", event.target.value)
                          }
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="head_scripts"
                    type="text"
                    render={({ field, form }) => (
                      <TextField
                        type="text"
                        label="Head Scripts"
                        variant="outlined"
                        name="head_scripts"
                        fullWidth
                        placeholder="Head Scripts"
                        InputLabelProps={{ shrink: true }}
                        value={socialSettingsData.head_scripts}
                        error={Boolean(
                          form.errors.head_scripts && form.touched.head_scripts
                        )}
                        helperText={
                          form.touched.head_scripts && form.errors.head_scripts
                        }
                        onChange={(event) =>{
                            handleInputChange(event);
                            setFieldValue("head_scripts", event.target.value)
                          }
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="body_scripts"
                    type="text"
                    render={({ field, form }) => (
                      <TextField
                        type="text"
                        label="Body Scripts"
                        variant="outlined"
                        name="body_scripts"
                        fullWidth
                        placeholder="Body Scripts"
                        InputLabelProps={{ shrink: true }}
                        value={socialSettingsData.body_scripts}
                        error={Boolean(
                          form.errors.body_scripts && form.touched.body_scripts
                        )}
                        helperText={
                          form.touched.body_scripts && form.errors.body_scripts
                        }
                        onChange={(event) =>{
                            handleInputChange(event);
                            setFieldValue("body_scripts", event.target.value)
                          }
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <MDBox
                mb={1}
                mt={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <MDButton 
                  variant="gradient" 
                  color="dark" 
                  size="medium"
                  type="submit"
                  disabled={props.updateSettings.buttonDisable}
                >
                  {props.updateSettings.loadingButtonContent != null
                      ? props.updateSettings.loadingButtonContent
                      : "Update settings"}
                </MDButton>
              </MDBox>
            </Form>
          )}
        </Formik>
      </MDBox>
    </Card>
  );
}


const mapStateToPros = (state) => ({
  settings: state.settings.settings,
  updateSettings:state.settings.updateSettings,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MobileSettings);
