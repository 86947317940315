import { Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createUpdateSubscriptionListStart } from "store/actions/SubscriptionActions";

const CreateSubscription = (props) => {
  const [inputData, setInputData] = useState({
    title: "",
    busd_limit: "",
    busdx_limit: "",
    description: "",
    nft_contract_address: ""
  });

  const createSubSchema = Yup.object().shape({
    title: Yup.string().required("Page Title is required *"),
    busd_limit: Yup.number()
      .min(1, "must be greater than 0")
      .required("BUSD is required *"),
    busdx_limit: Yup.number()
      .min(1, "must be greater than 0")
      .required("BUSDX is required *"),
    description: Yup.string().required("Description is required *"),
  });

  const handleSubmit = (values) => {
    props.dispatch(createUpdateSubscriptionListStart(values));
    setInputData({
      title: "",
      busd_limit: "",
      busdx_limit: "",
      description: "",
      nft_contract_address: ""
    });
  };

  const handleInputChange = (event) => {
    setInputData({
      ...inputData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <MDBox mb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={10}>
            <MDBox mt={6} mb={6} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add New Subscription
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the Subscription.
              </MDTypography>
            </MDBox>
            <Card>
              <Formik
                initialValues={inputData}
                validationSchema={createSubSchema}
                onSubmit={(values) => handleSubmit(values)}
                enableReinitialize
              >
                {({ touched, errors, isSubmitting, setFieldValue }) => (
                  <Form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={12}>
                        <MDBox p={3}>
                          <MDBox mt={3}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12}>
                                <Field
                                  type="text"
                                  name="title"
                                  render={({ field, form }) => (
                                    <TextField
                                      type="text"
                                      label="Title"
                                      variant="outlined"
                                      name="title"
                                      fullWidth
                                      placeholder="Enter Title"
                                      InputLabelProps={{ shrink: true }}
                                      value={inputData.title}
                                      error={Boolean(
                                        form.errors.title && form.touched.title
                                      )}
                                      helperText={
                                        form.touched.title && form.errors.title
                                      }
                                      onChange={(event) => {
                                        handleInputChange(event);
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox mt={3}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12}>
                                <Field
                                  type="text"
                                  name="description"
                                  render={({ field, form }) => (
                                    <TextField
                                      type="text"
                                      label="Description"
                                      variant="outlined"
                                      value={inputData.description}
                                      fullWidth
                                      placeholder="Enter description"
                                      name="description"
                                      multiline
                                      rows={4}
                                      maxRows={4}
                                      InputLabelProps={{ shrink: true }}
                                      error={Boolean(
                                        form.errors.description &&
                                          form.touched.description
                                      )}
                                      helperText={
                                        form.touched.description &&
                                        form.errors.description
                                      }
                                      onChange={(event) => {
                                        handleInputChange(event);
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>

                          <MDBox mt={3}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12}>
                                <Field
                                  type="text"
                                  name="nft_contract_address"
                                  render={({ field, form }) => (
                                    <TextField
                                      type="text"
                                      label="nft_contract_address"
                                      variant="outlined"
                                      name="nft_contract_address"
                                      fullWidth
                                      placeholder="Enter Contract Address"
                                      InputLabelProps={{ shrink: true }}
                                      value={inputData.nft_contract_address}
                                      error={Boolean(
                                        form.errors.nft_contract_address && form.touched.nft_contract_address
                                      )}
                                      helperText={
                                        form.touched.nft_contract_address && form.errors.nft_contract_address
                                      }
                                      onChange={(event) => {
                                        handleInputChange(event);
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>
                          
                          <MDBox mt={3}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Field
                                  type="number"
                                  name="busd_limit"
                                  render={({ field, form }) => (
                                    <TextField
                                      type="number"
                                      name="busd_limit"
                                      label="BUSD limit"
                                      variant="outlined"
                                      value={inputData.busd_limit}
                                      fullWidth
                                      placeholder="Enter BUSD limit"
                                      InputLabelProps={{ shrink: true }}
                                      error={Boolean(
                                        form.errors.busd_limit &&
                                          form.touched.busd_limit
                                      )}
                                      helperText={
                                        form.touched.busd_limit &&
                                        form.errors.busd_limit
                                      }
                                      onChange={(event) => {
                                        handleInputChange(event);
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Field
                                  type="number"
                                  name="busdx_limit"
                                  render={({ field, form }) => (
                                    <TextField
                                      type="number"
                                      name="busdx_limit"
                                      label="BUSDX limit"
                                      variant="outlined"
                                      fullWidth
                                      value={inputData.busdx_limit}
                                      placeholder="Enter BUSDX limit"
                                      InputLabelProps={{ shrink: true }}
                                      error={Boolean(
                                        form.errors.busdx_limit &&
                                          form.touched.busdx_limit
                                      )}
                                      helperText={
                                        form.touched.busdx_limit &&
                                        form.errors.busdx_limit
                                      }
                                      onChange={(event) => {
                                        handleInputChange(event);
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>

                          <MDBox mt={3}>
                            <Grid item xs={12} lg={12}>
                              <MDBox display="flex" justifyContent="center">
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  type="submit"
                                  disabled={
                                    props.createUpdateSubscription.buttonDisable
                                  }
                                >
                                  {props.createUpdateSubscription
                                    .loadingButtonContent !== null
                                    ? "Creating...."
                                    : "Create"}
                                </MDButton>
                              </MDBox>
                            </Grid>
                          </MDBox>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
};

const mapStateToPros = (state) => ({
  createUpdateSubscription: state.subscription.createUpdateSubscription,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateSubscription);
