// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "pages/account/components/FormField";

import MDButton from "components/MDButton";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { InputLabel, TextField } from "@mui/material";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { updateUserDetailsStart } from "store/actions/UserAction";

function BasicInfo(props) {
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    //confirm_email : "",
    description: "",
    //mobile : ""
  });

  const [profilePicture, setProfilePicture] = useState({
    previewImage: null,
    file: "",
    file_type: null,
  });

  useEffect(() => {
    if (!props.profile.loading && props.profile.data.length > 0) {
      setProfileData({
        ...profileData,
        name: props.profile.data[0].name,
        email: props.profile.data[0].email,
        description: props.profile.data[0].description,
      });
    }
  }, [props.profile.data]);

  const editProfileSchema = Yup.object().shape({
    name: Yup.string().required("Name is required *"),
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required *"),
    // confirm_email: Yup.string()
    //   .email("Invalid email address format")
    //   .required("Confirm Email is required *")
    //   .test("email-match", "Email must match", function (value) {
    //     return this.parent.email === value;
    //   }),
    //picture: Yup.mixed().required("Picture is required"),
    description: Yup.string().required("Description is required *"),
    //mobile: Yup.number().required("Mobile Number is required *"),
  });

  const handleInputChange = (event) => {
    setProfileData({
      ...profileData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event, fileType) => {
    if (event.currentTarget.type === "file") {
      let reader = new FileReader();
      let file = event.currentTarget.files[0];
      reader.onloadend = () => {
        setProfilePicture({
          ...profilePicture,
          previewImage: reader.result,
          file: file,
          file_type: file.type.match("image") ? "image" : "video",
        });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSubmit = (values) => {
    const newData = { ...values};
    props.dispatch(updateUserDetailsStart(newData));
  };

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox pt={0} pb={3} px={3}>
        <Formik
          initialValues={{
            name: profileData.name,
            email: profileData.email,
            //picture: profilePicture.file,
            //confirm_email: "",
            description: profileData.description,
            //mobile: "",
          }}
          validationSchema={editProfileSchema}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize
        >
          {({ touched, errors, isSubmitting, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <MDBox mb={2}>
                    <Field
                      type="text"
                      name="name"
                      render={({ field, form }) => (
                        <TextField
                          type="text"
                          label="Name"
                          name="name"
                          variant="outlined"
                          fullWidth
                          placeholder="john"
                          InputLabelProps={{ shrink: true }}
                          value={profileData.name}
                          error={Boolean(form.errors.name && form.touched.name)}
                          helperText={form.touched.name && form.errors.name}
                          onChange={(event) => {
                            handleInputChange(event);
                            setFieldValue("name", event.target.value);
                          }}
                        />
                      )}
                    />
                  </MDBox>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                  <MDBox mb={2}>
                    <Field
                      type="text"
                      name="last_name"
                      render={({ field, form }) => (
                        <TextField
                          type="text"
                          label="Last Name"
                          variant="outlined"
                          fullWidth
                          placeholder="john"
                          InputLabelProps={{ shrink: true }}
                          error={Boolean(
                            form.errors.last_name && form.touched.last_name
                          )}
                          helperText={
                            form.touched.last_name && form.errors.last_name
                          }
                          onChange={(event) =>
                            setFieldValue("last_name", event.target.value)
                          }
                        />
                      )}
                    />
                  </MDBox>
                </Grid> */}

                <Grid item xs={12} sm={6}>
                  <MDBox mb={2}>
                    <Field
                      name="email"
                      type="email"
                      render={({ field, form }) => (
                        <TextField
                          type="email"
                          label="Email"
                          name="email"
                          variant="outlined"
                          fullWidth
                          placeholder="Jhon@gmail.com"
                          InputLabelProps={{ shrink: true }}
                          value={profileData.email}
                          error={Boolean(
                            form.errors.email && form.touched.email
                          )}
                          helperText={form.touched.email && form.errors.email}
                          onChange={(event) => {
                            handleInputChange(event);
                            setFieldValue("email", event.target.value);
                          }}
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <InputLabel shrink>Profile picture</InputLabel>
                  <MDButton
                    variant="outlined"
                    color="secondary"
                    component="label"
                    size={"medium"}
                    mt={3}
                  >
                    Profile picture
                    <input
                      type="file"
                      name="picture"
                      hidden
                      onChange={(event) => handleFileChange(event, "site_logo")}
                    />
                  </MDButton>
                  {profilePicture.previewImage && (
                    <MDBox
                      component="img"
                      src={profilePicture.previewImage}
                      width={"100%"}
                      height="auto"
                      maxHeight={"300px"}
                      sx={{ objectFit: "contain" }}
                      mt={3}
                    />
                  )}
                  <ErrorMessage 
                    component={"p"}
                    name="picture"
                    className="custom-invalid-message"
                  />
                </Grid> */}

                {/* <Grid item xs={12} sm={6}>
                  <MDBox mb={2}>
                    <Field
                      name="confirm_email"
                      type="email"
                      render={({ field, form }) => (
                        <TextField
                          type="email"
                          label="Confirm Email"
                          variant="outlined"
                          fullWidth
                          placeholder="Jhon@gmail.com"
                          InputLabelProps={{ shrink: true }}
                        
                          error={Boolean(
                            form.errors.confirm_email &&
                              form.touched.confirm_email
                          )}
                          helperText={
                            form.touched.confirm_email &&
                            form.errors.confirm_email
                          }
                          onChange={(event) =>
                            setFieldValue("confirm_email", event.target.value)
                          }
                        />
                      )}
                    />
                  </MDBox>
                </Grid> */}

                {/* <Grid item xs={12} sm={6}>
                  <MDBox mb={2} mt={2}>
                    <Field
                      name="description"
                      type="text"
                      render={({ field, form }) => (
                        <TextField
                          type="text"
                          label="Description"
                          variant="outlined"
                          name="description"
                          fullWidth
                          placeholder="Description"
                          InputLabelProps={{ shrink: true }}
                          value={profileData.description}
                          error={Boolean(
                            form.errors.description && form.touched.description
                          )}
                          helperText={
                            form.touched.description && form.errors.description
                          }
                          onChange={(event) =>{
                              handleInputChange(event);
                              setFieldValue("description", event.target.value)
                            }
                          }
                        />
                      )}
                    />
                  </MDBox>
                </Grid> */}

                {/* <Grid item xs={12} sm={6}>
                  <MDBox mb={2}>
                    <Field
                      name="mobile"
                      type="number"
                      render={({ field, form }) => (
                        <TextField
                          type="number"
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          placeholder="+91 12345678"
                          InputLabelProps={{ shrink: true }}
                          error={Boolean(
                            form.errors.mobile && form.touched.mobile
                          )}
                          helperText={form.touched.mobile && form.errors.mobile}
                          onChange={(event) =>
                            setFieldValue("mobile", event.target.value)
                          }
                        />
                      )}
                    />
                  </MDBox>
                </Grid> */}

                <MDBox ml="auto" mt={3}>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    size="medium"
                    type="submit"
                    disabled={props.updateUserDetails.buttonDisable}
                  >
                    {props.updateUserDetails.loadingButtonContent != ""
                      ? props.updateUserDetails.loadingButtonContent
                      : "Update profile"}
                  </MDButton>
                </MDBox>
              </Grid>
            </Form>
          )}
        </Formik>
      </MDBox>
    </Card>
  );
}

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  updateUserDetails: state.users.updateUserDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(BasicInfo);
