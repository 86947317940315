import { Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  createUpdateSubscriptionListStart,
  getSingleSubscriptionStart,
} from "store/actions/SubscriptionActions";
import { useParams, useNavigate, Link } from "react-router-dom";

const EditSubscription = (props) => {
  const { subscription_id } = useParams();

  const navigate = useNavigate();

  const { singleSubscription, createUpdateSubscription } = props;

  const [inputData, setInputData] = useState({
    title: "",
    busd_limit: "",
    busdx_limit: "",
    description: "",
    nft_contract_address: "",
    subscription_id: subscription_id,
  });

  const createSubSchema = Yup.object().shape({
    title: Yup.string().required("Page Title is required *"),
    busd_limit: Yup.number()
      .min(1, "must be greater than 0")
      .required("BUSD is required *"),
    busdx_limit: Yup.number()
      .min(1, "must be greater than 0")
      .required("BUSDX is required *"),
    description: Yup.string().required("Description is required *"),
  });

  const handleSubmit = (values) => {
    props.dispatch(createUpdateSubscriptionListStart(values));
  };

  const handleInputChange = (event) => {
    setInputData({
      ...inputData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    props.dispatch(
      getSingleSubscriptionStart({ subscription_id: subscription_id })
    );
  }, []);

  useEffect(() => {
    if (
      !singleSubscription.loading &&
      Object.keys(singleSubscription.data).length > 0
    ) {
      setInputData({
        ...inputData,
        title: singleSubscription.data.title,
        busd_limit: singleSubscription.data.busd_limit,
        busdx_limit: singleSubscription.data.busdx_limit,
        description: singleSubscription.data.description,
        nft_contract_address: singleSubscription.data.nft_contract_address
      });
    }
  }, [singleSubscription.data]);

  useEffect(() => {
    if (
      !createUpdateSubscription.loading &&
      Object.keys(createUpdateSubscription.data).length > 0
    ) {
      setTimeout(() => {
        navigate("/view_subscriptions_list");
      }, 1000);
    }
  }, [createUpdateSubscription.loading]);

  return (
    <>
      {singleSubscription.loading ? (
        <MDBox
          mt={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <MDTypography variant="h4" fontWeight="bold">
            loading
          </MDTypography>
        </MDBox>
      ) : Object.keys(singleSubscription.data).length > 0 ? (
        <>
          <MDBox mb={3}>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={10}>
                <MDBox mt={6} mb={6} textAlign="center">
                  <MDBox mb={1}>
                    <MDTypography variant="h3" fontWeight="bold">
                      Edit Subscription
                    </MDTypography>
                  </MDBox>
                  <MDTypography
                    variant="h5"
                    fontWeight="regular"
                    color="secondary"
                  >
                    This information will describe more about the Subscription.
                  </MDTypography>
                </MDBox>
                <Card>
                  <Formik
                    initialValues={inputData}
                    validationSchema={createSubSchema}
                    onSubmit={(values) => handleSubmit(values)}
                    enableReinitialize
                  >
                    {({ touched, errors, isSubmitting, setFieldValue }) => (
                      <Form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={12}>
                            <MDBox p={3}>
                              <MDBox mt={3}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={12}>
                                    <Field
                                      type="text"
                                      name="title"
                                      render={({ field, form }) => (
                                        <TextField
                                          type="text"
                                          label="Title"
                                          variant="outlined"
                                          name="title"
                                          fullWidth
                                          placeholder="Enter Title"
                                          InputLabelProps={{ shrink: true }}
                                          value={inputData.title}
                                          error={Boolean(
                                            form.errors.title &&
                                              form.touched.title
                                          )}
                                          helperText={
                                            form.touched.title &&
                                            form.errors.title
                                          }
                                          onChange={(event) => {
                                            handleInputChange(event);
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </MDBox>
                              <MDBox mt={3}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={12}>
                                    <Field
                                      type="text"
                                      name="description"
                                      render={({ field, form }) => (
                                        <TextField
                                          type="text"
                                          label="Description"
                                          variant="outlined"
                                          value={inputData.description}
                                          fullWidth
                                          placeholder="Enter description"
                                          name="description"
                                          multiline
                                          rows={4}
                                          maxRows={4}
                                          InputLabelProps={{ shrink: true }}
                                          error={Boolean(
                                            form.errors.description &&
                                              form.touched.description
                                          )}
                                          helperText={
                                            form.touched.description &&
                                            form.errors.description
                                          }
                                          onChange={(event) => {
                                            handleInputChange(event);
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </MDBox>
                              <MDBox mt={3}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={12}>
                                    <Field
                                      type="text"
                                      name="nft_contract_address"
                                      render={({ field, form }) => (
                                        <TextField
                                          type="text"
                                          label="Contract Address"
                                          variant="outlined"
                                          name="nft_contract_address"
                                          fullWidth
                                          placeholder="Enter Contract Address"
                                          InputLabelProps={{ shrink: true }}
                                          value={inputData.nft_contract_address}
                                          error={Boolean(
                                            form.errors.nft_contract_address &&
                                              form.touched.nft_contract_address
                                          )}
                                          helperText={
                                            form.touched.nft_contract_address &&
                                            form.errors.nft_contract_address
                                          }
                                          onChange={(event) => {
                                            handleInputChange(event);
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </MDBox>
                              <MDBox mt={3}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <Field
                                      type="number"
                                      name="busd_limit"
                                      render={({ field, form }) => (
                                        <TextField
                                          type="number"
                                          name="busd_limit"
                                          label="BUSD limit"
                                          variant="outlined"
                                          value={inputData.busd_limit}
                                          fullWidth
                                          placeholder="Enter BUSD limit"
                                          InputLabelProps={{ shrink: true }}
                                          error={Boolean(
                                            form.errors.busd_limit &&
                                              form.touched.busd_limit
                                          )}
                                          helperText={
                                            form.touched.busd_limit &&
                                            form.errors.busd_limit
                                          }
                                          onChange={(event) => {
                                            handleInputChange(event);
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Field
                                      type="number"
                                      name="busdx_limit"
                                      render={({ field, form }) => (
                                        <TextField
                                          type="number"
                                          name="busdx_limit"
                                          label="BUSDX limit"
                                          variant="outlined"
                                          fullWidth
                                          value={inputData.busdx_limit}
                                          placeholder="Enter BUSDX limit"
                                          InputLabelProps={{ shrink: true }}
                                          error={Boolean(
                                            form.errors.busdx_limit &&
                                              form.touched.busdx_limit
                                          )}
                                          helperText={
                                            form.touched.busdx_limit &&
                                            form.errors.busdx_limit
                                          }
                                          onChange={(event) => {
                                            handleInputChange(event);
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </MDBox>

                              <MDBox mt={3}>
                                <Grid item xs={12} lg={12}>
                                  <MDBox
                                    display="flex"
                                    justifyContent="center"
                                    gap="1rem"
                                    flexWrap="wrap"
                                  >
                                    <MDButton
                                      variant="gradient"
                                      color="info"
                                      type="submit"
                                      disabled={
                                        createUpdateSubscription.buttonDisable
                                      }
                                    >
                                      {createUpdateSubscription.loadingButtonContent !==
                                      null
                                        ? "Updating...."
                                        : "Update"}
                                    </MDButton>
                                    <MDButton
                                      onClick={() => navigate(-1)}
                                      variant="gradient"
                                      color="info"
                                      type="button"
                                      disabled={
                                        createUpdateSubscription.buttonDisable
                                      }
                                    >
                                      Cancel
                                    </MDButton>
                                  </MDBox>
                                </Grid>
                              </MDBox>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      ) : (
        <MDBox
          mt={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <MDTypography variant="h3" fontWeight="bold">
            Something went wrong
          </MDTypography>
        </MDBox>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  createUpdateSubscription: state.subscription.createUpdateSubscription,
  singleSubscription: state.subscription.singleSubscription,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditSubscription);
