import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDButton from "components/MDButton";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { 
  viewStaticPagesStart,
  addStaticPagesStart,
} from "store/actions/LooksupActions";
import { connect } from "react-redux";

const EditPage = (props) => {
  const pageTypeOptions = [
    {label: 'Privacy', id: 'privacy'},
    {label: 'Terms', id: 'terms'},
    {label: 'About', id: 'about'},
    {label: 'Contact', id: 'contact'},
  ];
  const {static_page_id } = useParams();

  const [pageData, setPageData] = useState({
    title: "",
    page_type: "",
    description: "",
  });

  const editPageSchema = Yup.object().shape({
    title: Yup.string().required("Page Title is required *"),
    page_type: Yup.string().required("Page Type is required *"),
    description: Yup.string(),
  });

  const [editorValue, setEditorValue] = useState('');

  useEffect(() => {
    props.dispatch(viewStaticPagesStart({static_page_id:static_page_id}))
  },[])

  const handleSubmit = (values) => {
    props.dispatch(addStaticPagesStart(values))
  };

  const handleInputChange = (event) => {
    setPageData({
      ...pageData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (value) => {
    setEditorValue(value);
  };

  useEffect(() => {
    if (!props.viewStaticPage.loading && props.viewStaticPage.data) {
      setPageData({
        ...pageData,
        title: props.viewStaticPage.data.title,
        page_type: props.viewStaticPage.data.page_type,
        description: props.viewStaticPage.data.description,
        static_page_id:props.viewStaticPage.data.static_page_id,
      });
      setEditorValue(props.viewStaticPage.data.description);
    }
  }, [props.viewStaticPage.data]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Edit Page</MDTypography>
        <Formik
          initialValues={{
            title: pageData.title,
            page_type: pageData.page_type,
            description: pageData.description,
            static_page_id:pageData.static_page_id,
          }}
          validationSchema={editPageSchema}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize
        >
          {({ touched, errors, isSubmitting, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3} mt={2}>
                <Grid item xs={12} sm={6} mt={2}>
                  <MDBox mb={0}>
                    <Field
                      type="text"
                      name="title"
                      render={({ field, form }) => (
                        <TextField
                          type="text"
                          label="Page Name"
                          name="title"
                          variant="standard"
                          fullWidth
                          value={pageData.title}
                          placeholder="Page Name"
                          InputLabelProps={{ shrink: true }}
                          error={Boolean(
                            form.errors.title && form.touched.title
                          )}
                          helperText={
                            form.touched.title && form.errors.title
                          }
                          onChange={(event) => {
                              handleInputChange(event)
                              setFieldValue("title", event.target.value)
                            } 
                          }
                        />
                      )}
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MDBox mb={0}>
                    <MDBox mb={0} display="inline-block">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize"
                      >
                        Choose Pagetype
                      </MDTypography>
                    </MDBox>
                    <Autocomplete
                      getOptionLabel={(option) => option.label}
                      options = {pageTypeOptions}
                      onChange={(event) => {
                          handleInputChange(event)
                          setFieldValue("page_type", event.target.value)
                        }
                      }
                      renderInput={(params) => (
                        <Field
                          type="text"
                          name="page_type"
                          render={({ field, form }) => (
                            <TextField
                              {...params}
                              variant="standard"
                              error={Boolean(
                                form.errors.page_type &&
                                  form.touched.page_type
                              )}
                              helperText={
                                form.touched.page_type &&
                                form.errors.page_type
                              }
                              
                            />
                          )}
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox mt={0}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <MDBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <MDTypography
                            component="label"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Description
                          </MDTypography>
                        </MDBox>
                        <MDEditor
                          value={editorValue}
                          onChange={(event) => {
                              handleChange(event)
                              setFieldValue("description", event)
                            }
                          }
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox mt={3}>
                <Grid item xs={12} lg={12}>
                  <MDBox display="flex" justifyContent="center">
                    <MDButton variant="gradient" color="success" type="submit">
                      Save Changes
                    </MDButton>
                  </MDBox>
                </Grid>
              </MDBox>
            </Form>
          )}
        </Formik>
      </MDBox>
    </Card>
  );
};

const mapStateToPros = (state) => ({
  viewStaticPage: state.looksup.viewStaticPage,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default  connect(mapStateToPros, mapDispatchToProps)(EditPage)