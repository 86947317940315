import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import { Grid, Icon, Tooltip } from "@mui/material";
import SubscriptionCard from "../components/SubscriptionCard";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MDButton from "components/MDButton";
import { connect } from "react-redux";
import { getSubscriptionListStart } from "store/actions/SubscriptionActions";

const ViewSubscriptions = (props) => {
  const { subscriptions } = props;

  useEffect(() => {
    props.dispatch(getSubscriptionListStart());
  }, []);

  return (
    <>
      {subscriptions.loading ? (
        <MDBox
          mt={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <MDTypography variant="h4" fontWeight="bold">
            loading
          </MDTypography>
        </MDBox>
      ) : subscriptions.data.total > 0 ? (
        <>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              {subscriptions.data.subscriptions.map((sub, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <MDBox mt={0} height="100%">
                    <SubscriptionCard
                      title={sub.title}
                      description={
                        sub.description == "" || sub.description == null
                          ? ""
                          : sub.description
                      }
                      BUSD={sub.busd_limit}
                      BUSDX={sub.busdx_limit}
                      nft_contract_address={sub.nft_contract_address}
                      id={sub.subscription_id}
                    />
                  </MDBox>
                </Grid>
              ))}
            </Grid>
          </MDBox>
        </>
      ) : (
        <MDBox
          mt={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <MDTypography variant="h3" fontWeight="bold">
            No data found
          </MDTypography>
        </MDBox>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  subscriptions: state.subscription.subscriptions,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ViewSubscriptions);
