// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import { connect } from "react-redux";
import { changePasswordStart } from "store/actions/UserAction";

function ChangePassword(props) {
  const changePasswordSchema = Yup.object().shape({
    old_password: Yup.string()
      .required("Current password is required *")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    password: Yup.string()
      .required("New Password is required *")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .test(
        "passwords-match",
        "New password should not match current password",
        function (value) {
          return this.parent.old_password !== value;
        }
      ),
    password_confirmation: Yup.string()
      .required("Confrim New Password *")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number",
    "One Uppercase",
    "Atleast One Lowercase",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1}
      >
        <MDTypography
          variant="button"
          color="text"
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  const handleSubmit = (values) => {
    props.dispatch(changePasswordStart(values));
  };

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      <MDBox pt={1} pb={3} px={3}>
        <Formik
          initialValues={{
            old_password: "",
            password: "",
            password_confirmation: "",
          }}
          validationSchema={changePasswordSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ touched, errors, isSubmitting, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MDBox mb={2}>
                    <Field
                      type="password"
                      name="old_password"
                      render={({ field, form }) => (
                        <TextField
                          type="password"
                          label="Current Password"
                          variant="outlined"
                          fullWidth
                          placeholder="*******"
                          InputLabelProps={{ shrink: true }}
                          error={Boolean(
                            form.errors.old_password &&
                              form.touched.old_password
                          )}
                          helperText={
                            form.touched.old_password &&
                            form.errors.old_password
                          }
                          onChange={(event) =>
                            setFieldValue(
                              "old_password",
                              event.target.value
                            )
                          }
                        />
                      )}
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12}>
                  <MDBox mb={2}>
                    <Field
                      type="password"
                      name="password"
                      render={({ field, form }) => (
                        <TextField
                          type="password"
                          label="New Password"
                          variant="outlined"
                          fullWidth
                          placeholder="*******"
                          InputLabelProps={{ shrink: true }}
                          error={Boolean(
                            form.errors.password &&
                              form.touched.password
                          )}
                          helperText={
                            form.touched.password &&
                            form.errors.password
                          }
                          onChange={(event) =>
                            setFieldValue("password", event.target.value)
                          }
                        />
                      )}
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12}>
                  <MDBox mb={2}>
                    <Field
                      type="password"
                      name="password_confirmation"
                      render={({ field, form }) => (
                        <TextField
                          type="password"
                          label="Confirm New Passwoed"
                          variant="outlined"
                          fullWidth
                          placeholder="*******"
                          InputLabelProps={{ shrink: true }}
                          error={Boolean(
                            form.errors.password_confirmation &&
                              form.touched.password_confirmation
                          )}
                          helperText={
                            form.touched.password_confirmation &&
                            form.errors.password_confirmation
                          }
                          onChange={(event) =>
                            setFieldValue(
                              "password_confirmation",
                              event.target.value
                            )
                          }
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox mt={3} mb={1}>
                <MDTypography variant="h5">Password requirements</MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography variant="body2" color="text">
                  Please follow this guide for a strong password
                </MDTypography>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                flexWrap="wrap"
              >
                <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                  {renderPasswordRequirements}
                </MDBox>
                <MDBox ml="auto">
                  <MDButton
                    variant="gradient"
                    color="dark"
                    size="medium"
                    type="submit"
                    disabled={props.changePassword.buttonDisable}
                  >
                    {props.changePassword.loadingButtonContent != null
                      ? props.changePassword.loadingButtonContent
                      : "Update password"}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Form>
          )}
        </Formik>
      </MDBox>
    </Card>
  );
}

const mapStateToPros = (state) => ({
  changePassword: state.users.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ChangePassword);
