import { Card, Grid, Tooltip } from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";
import { grey } from "@mui/material/colors";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MDButton from "components/MDButton";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {getSingleTransactionStart} from "store/actions/WalletActions"

const Viewtransaction = (props) => {

  const {transaction_id} = useParams();

  useEffect(() => {
    props.dispatch(getSingleTransactionStart({transaction_id : transaction_id}))
  },[])

  return (
    <>
      <MDBox my={3}>
        <Card>
          <Grid container spacing={3} p={3}>
            <Grid item xs={12}>
              <MDBox mt={0} sx={{ borderBottom: `1px solid ${grey[600]}` }}>
                <MDBox mb={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={5} sm={5} md={3}>
                      <MDBox mb={0}>
                        <MDTypography
                          component="p"
                          variant="h6"
                          fontWeight="bold"
                          color="text"
                          textTransform="capitalize"
                        >
                          Transaction Hash :
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <MDBox mb={0}>
                        <MDBox display="flex" alignItems="center">
                          <MDTypography
                            component="p"
                            variant="h6"
                            fontWeight="regular"
                            color="text"
                            textTransform="capitalize"
                            noWrap
                          >
                            0x9c12b4d8427370ec832495879e838aba4b898ae4fa7270ac64e0d8fe49fb4e5c
                          </MDTypography>

                          <MDButton variant="text" color="light" type="button">
                            {" "}
                            <ContentCopyIcon />
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mb={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={5} sm={5} md={3}>
                      <MDBox mb={0}>
                        <MDTypography
                          component="p"
                          variant="h6"
                          fontWeight="bold"
                          color="text"
                          textTransform="capitalize"
                        >
                          Status :
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <MDBox mb={0}>
                        <MDBadge
                          variant="gradient"
                          color="success"
                          badgeContent={"Success"}
                          container
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mb={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={5} sm={5} md={3}>
                      <MDBox mb={0}>
                        <MDTypography
                          component="p"
                          variant="h6"
                          fontWeight="bold"
                          color="text"
                          textTransform="capitalize"
                        >
                          Block :
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <MDBox mb={0}>
                        <MDTypography
                          component="p"
                          variant="h6"
                          fontWeight="regular"
                          color="text"
                          textTransform="capitalize"
                        >
                          14397050
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mb={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={5} sm={5} md={3}>
                      <MDBox mb={0}>
                        <MDTypography
                          component="p"
                          variant="h6"
                          fontWeight="bold"
                          color="text"
                          textTransform="capitalize"
                        >
                          Timestamp:
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <MDBox mb={0}>
                        <MDTypography
                          component="p"
                          variant="h6"
                          fontWeight="regular"
                          color="text"
                          textTransform="capitalize"
                        >
                          1 min ago (Mar-16-2022 10:28:29 AM +UTC)
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox mt={0} sx={{ borderBottom: `1px solid ${grey[600]}` }}>
                <MDBox mb={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={5} sm={5} md={3}>
                      <MDBox mb={0}>
                        <MDTypography
                          component="p"
                          variant="h6"
                          fontWeight="bold"
                          color="text"
                          textTransform="capitalize"
                        >
                          From :
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <MDBox mb={0}>
                        <MDBox display="flex" alignItems="center">
                          <MDTypography
                            component="p"
                            variant="h6"
                            fontWeight="regular"
                            color="text"
                            textTransform="capitalize"
                            noWrap
                          >
                            0x9c12b4d8427370ec832495879e838aba4b898ae4fa7270ac64e0d8fe49fb4e5c
                          </MDTypography>
                          <MDButton variant="text" color="light" type="button">
                            {" "}
                            <ContentCopyIcon />
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mb={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={5} sm={5} md={3}>
                      <MDBox mb={0}>
                        <MDTypography
                          component="p"
                          variant="h6"
                          fontWeight="bold"
                          color="text"
                          textTransform="capitalize"
                        >
                          To :
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <MDBox mb={0}>
                        <MDBox display="flex" alignItems="center">
                          <MDTypography
                            component="p"
                            variant="h6"
                            fontWeight="regular"
                            color="text"
                            textTransform="capitalize"
                            noWrap
                          >
                            0x9c12b4d8427370ec832495879e838aba4b898ae4fa7270ac64e0d8fe49fb4e5c
                          </MDTypography>
                          <MDButton variant="text" color="light" type="button">
                            {" "}
                            <ContentCopyIcon />
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox mt={0} sx={{ borderBottom: `1px solid ${grey[600]}` }}>
                <MDBox mb={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDBox mb={0}>
                        <MDTypography
                          component="p"
                          variant="h6"
                          fontWeight="bold"
                          color="text"
                          textTransform="capitalize"
                        >
                          Tokens Transferred :
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                      <MDBox mb={0}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <Grid container spacing={3}>
                              <Grid item xs={5} md={4} lg={3} >
                                <MDTypography
                                  component="p"
                                  variant="h6"
                                  fontWeight="bold"
                                  color="text"
                                  textTransform="capitalize"
                                >
                                  From :
                                </MDTypography>
                              </Grid>
                              <Grid item xs={7} md={8} lg={9}>
                                <Tooltip
                                  title="0x705c4310e0250df2595cb5725adc90ed7defba01"
                                  arrow
                                  placement="top"
                                >
                                  <MDTypography
                                    component="p"
                                    variant="h6"
                                    fontWeight="regular"
                                    color="text"
                                    textTransform="capitalize"
                                    noWrap
                                  >
                                    0x705c4310e0250df2595cb5725adc90ed7defba01
                                  </MDTypography>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Grid container spacing={2}>
                              <Grid item xs={5} md={3} lg={2}>
                                <MDTypography
                                  component="p"
                                  variant="h6"
                                  fontWeight="bold"
                                  color="text"
                                  textTransform="capitalize"
                                >
                                  To :
                                </MDTypography>
                              </Grid>
                              <Grid item xs={7} md={9} lg={10}>
                                <Tooltip
                                  title="0x705c4310e0250df2595cb5725adc90ed7defba01"
                                  arrow
                                  placement="top"
                                >
                                  <MDTypography
                                    component="p"
                                    variant="h6"
                                    fontWeight="regular"
                                    color="text"
                                    textTransform="capitalize"
                                    noWrap
                                  >
                                    0x705c4310e0250df2595cb5725adc90ed7defba01
                                  </MDTypography>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Grid container spacing={2}>
                              <Grid item xs={5} md={3} lg={2} >
                                <MDTypography
                                  component="p"
                                  variant="h6"
                                  fontWeight="bold"
                                  color="text"
                                  textTransform="capitalize"
                                >
                                  For :
                                </MDTypography>
                              </Grid>
                              <Grid item xs={7} md={9} lg={10}>
                                <MDTypography
                                  component="p"
                                  variant="h6"
                                  fontWeight="regular"
                                  color="text"
                                  textTransform="capitalize"
                                  noWrap
                                >
                                  0.05410431 ($97.08)
                                </MDTypography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox mt={0}>
                <MDBox mb={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={5} sm={5} md={3}>
                      <MDBox mb={0}>
                        <MDTypography
                          component="p"
                          variant="h6"
                          fontWeight="bold"
                          color="text"
                          textTransform="capitalize"
                        >
                          Value :
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <MDBox mb={0}>
                        <MDTypography
                          component="p"
                          variant="h6"
                          fontWeight="regular"
                          color="text"
                          textTransform="capitalize"
                          noWrap
                        >
                          0.01 BNB
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mb={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={5} sm={5} md={3}>
                      <MDBox mb={0}>
                        <MDTypography
                          component="p"
                          variant="h6"
                          fontWeight="bold"
                          color="text"
                          textTransform="capitalize"
                        >
                          Transaction Fee :
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={7} sm={7} md={9}>
                      <MDBox mb={0}>
                        <MDTypography
                          component="p"
                          variant="h6"
                          fontWeight="regular"
                          color="text"
                          textTransform="capitalize"
                          noWrap
                        >
                          0.00140524664 BNB ($3.78)
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    </>
  );
};

const mapStateToPros = (state) => ({
  viewWallet: state.wallet.viewWallet,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}


export default connect(mapStateToPros , mapDispatchToProps)(Viewtransaction);
