import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import { userLoginStart } from "store/actions/UserAction";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";

const Signin = (props) => {
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required *"),
    password: Yup.string()
      .required("Password is required *")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
  });

  const handleLogin = (values) => {
    const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

    const newData = { ...values, user_timezone: timezone };

    props.dispatch(userLoginStart(newData));
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to Sign In
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={(values) => handleLogin(values)}
          >
            {({ touched, errors, isSubmitting, setFieldValue }) => (
              <Form noValidate autoComplete="off">
                <MDBox mb={2}>
                  <Field
                    type="email"
                    name="email"
                 
                    render={({ field, form }) => (
                      <TextField
                        type="email"
                        label="Email"
                        variant="standard"
                        fullWidth
                        placeholder="john@example.com"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(form.errors.email && form.touched.email)}
                        helperText={form.touched.email && form.errors.email}
                        onChange={(event) =>
                          setFieldValue("email", event.target.value)
                        }
                      />
                    )}
                  />
                </MDBox>

                <MDBox mb={2}>
                  <Field
                    name="password"
                    type="password"
                    render={({ field, form }) => (
                      <TextField
                        type="password"
                        label="Password"
                        variant="standard"
                        fullWidth
                        placeholder="*****"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(
                          form.errors.password && form.touched.password
                        )}
                        helperText={
                          form.touched.password && form.errors.password
                        }
                        onChange={(event) =>
                          setFieldValue("password", event.target.value)
                        }
                      />
                    )}
                  />
                </MDBox>

                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    disabled={props.login.buttonDisable}
                    type="submit"
                  >
                    {props.login.loadingButtonContent !== null
                      ? props.login.loadingButtonContent
                      : "Sign In"}
                  </MDButton>
                </MDBox>
                {/* <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Don&apos;t have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/register"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign up
                    </MDTypography>
                  </MDTypography>
                </MDBox> */}
                {/* <MDBox mt={0} mb={1} textAlign="center">
                  <MDTypography
                    component={Link}
                    to="/forgot_password"
                    variant="button"
                    color="error"
                    fontWeight="medium"
                    textGradient
                  >
                    Forgot password
                  </MDTypography>
                </MDBox> */}
              </Form>
            )}
          </Formik>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Signin);
