import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import MDAvatar from "components/MDAvatar";

// Images
import profilePicture from "assets/images/team-3.jpg";
import { logoutStart } from "store/actions/UserAction";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "store/actions/UserAction";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

function Sidenav(props) {
  const { color, brand, brandName, routes, ...rest } = props;

  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  const navigate = useNavigate();

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      //setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const handleLogout = () => {
    props.dispatch(logoutStart());
    navigate("/")
    localStorage.removeItem("userId")
    localStorage.removeItem("accessToken")
  };

  useEffect(() => {
    if(!props.profile.loading && Object.keys(props.profile.data).length == 0){
      props.dispatch(fetchUserDetailsStart())
    }
  },[])

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox
          component={NavLink}
          to="/dashboard"
          display="flex"
          alignItems="center"
          gap={"0.5rem"}
        >
          {brand && (
            <MDBox component="img" src={brand} alt="Brand" width="2rem" />
          )}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography
              component="h6"
              variant="button"
              fontWeight="medium"
              color={textColor}
            >
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>
        {!props.profile.loading && props.profile.data.length > 0 && (
          <>
            <SidenavCollapse
              name={props.profile.data[0].name}
              icon={
                // <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />
                  <Icon>account_circle</Icon>
              }
              active={"account" === collapseName}
              open={openCollapse === "account"}
              onClick={() =>
                openCollapse === "account"
                  ? setOpenCollapse(false)
                  : setOpenCollapse("account")
              }
            >
              <SidenavList>
                <NavLink
                  to={"/account/profile"}
                  sx={{ textDecoration: "none" }}
                >
                  <SidenavItem
                    name={"My Profile"}
                    active={"/account/profile" === pathname}
                  />
                </NavLink>
                <NavLink
                  to={"/account/settings"}
                  sx={{ textDecoration: "none" }}
                >
                  <SidenavItem
                    name={"Settings"}
                    active={"/account/settings" === pathname}
                  />
                </NavLink>
                <MDTypography
                  variant="button"
                  color="text"
                  onClick={() => handleLogout()}
                  type="button"
                >
                  <SidenavItem name={"Logout"}></SidenavItem>
                </MDTypography>
              </SidenavList>
            </SidenavCollapse>
            <Divider
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          </>
        )}

        <NavLink to={"/dashboard"}>
          <SidenavCollapse
            name={"Dashboard"}
            icon={<Icon fontSize="medium">dashboard</Icon>}
            noCollapse={true}
            active={"/dashboard" == pathname}
            onClick={() => setOpenCollapse(false)}
          ></SidenavCollapse>
        </NavLink>
        <MDTypography
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          wallets management
        </MDTypography>
        <NavLink to={"/wallets"}>
          <SidenavCollapse
            name={"Wallets"}
            icon={<Icon fontSize="medium">content_paste</Icon>}
            noCollapse={true}
            active={"/wallets" == pathname}
            onClick={() => setOpenCollapse(false)}
          ></SidenavCollapse>
        </NavLink>
        <NavLink to={"/recent_transactions"}>
          <SidenavCollapse
            name={"Recent Transaction"}
            icon={<Icon fontSize="medium">receipt_long</Icon>}
            noCollapse={true}
            active={"/recent_transactions" == pathname}
            onClick={() => setOpenCollapse(false)}
          ></SidenavCollapse>
        </NavLink>
        <NavLink to={"/transaction_history"}>
          <SidenavCollapse
            name={"Transaction History"}
            icon={<Icon fontSize="medium">receipt_long</Icon>}
            noCollapse={true}
            active={"/transaction_history" == pathname}
            onClick={() => setOpenCollapse(false)}
          ></SidenavCollapse>
        </NavLink>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        <MDTypography
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          subscription management
        </MDTypography>
        <SidenavCollapse
          name={"Subscription"}
          icon={<CardGiftcardIcon />}
          active={"subs" === openCollapse}
          open={openCollapse === "subs"}
          onClick={() =>
            openCollapse === "subs"
              ? setOpenCollapse(false)
              : setOpenCollapse("subs")
          }
        >
          <SidenavList>
            {/* <NavLink to={"/create_subscription"} sx={{ textDecoration: "none" }}>
              <SidenavItem
                name={"Add Subscription"}
                active={"/create_subscription" === pathname}
              />
            </NavLink> */}
            <NavLink to={"/view_subscriptions_list"} sx={{ textDecoration: "none" }}>
              <SidenavItem
                name={"View Subscriptions"}
                active={"/view_subscriptions_list" === pathname}
              />
            </NavLink>
          </SidenavList>
        </SidenavCollapse>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        <MDTypography
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          lookup's management
        </MDTypography>
        <SidenavCollapse
          name={"Static pages"}
          icon={<Icon fontSize="medium">dashboard</Icon>}
          active={"static" === openCollapse}
          open={openCollapse === "static"}
          onClick={() =>
            openCollapse === "static"
              ? setOpenCollapse(false)
              : setOpenCollapse("static")
          }
        >
          <SidenavList>
            <NavLink to={"/add_page"} sx={{ textDecoration: "none" }}>
              <SidenavItem
                name={"Add Page"}
                active={"/add_page" === pathname}
              />
            </NavLink>
            <NavLink to={"/view_pages"} sx={{ textDecoration: "none" }}>
              <SidenavItem
                name={"View Pages"}
                active={"/view_pages" === pathname}
              />
            </NavLink>
          </SidenavList>
        </SidenavCollapse>
        {/* <NavLink to={"/settings"}>
          <SidenavCollapse
            name={"Settings"}
            icon={<Icon fontSize="medium">settings</Icon>}
            noCollapse={true}
          ></SidenavCollapse>
        </NavLink> */}
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        <MDTypography
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          Account management
        </MDTypography>
        <NavLink to={"/my_profile"}>
          <SidenavCollapse
            name={"My profille"}
            active={"my_profile" === pathname}
            icon={<Icon fontSize="medium">account_circle</Icon>}
            noCollapse={true}
            onClick={() => setOpenCollapse(false)}
          ></SidenavCollapse>
        </NavLink>
        <MDTypography
          variant="button"
          color="text"
          onClick={() => handleLogout()}
          type="button"
        >
          <SidenavCollapse
            name={"Logout"}
            icon={<Icon fontSize="medium">logout</Icon>}
            noCollapse={true}
          ></SidenavCollapse>
        </MDTypography>
      </List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Sidenav);
