import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "Environment";
import {
  GET_SUBSCRIPTION_LIST_START,
  CREATE_UPDATE_SUBSCRIPTION_START,
  GET_SINGLE_SUBSCRIPTION_START,
  DELETE_SUBSCRIPTION_START,
  GET_SUBSCRIBERS_LIST_START
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "components/helper/ToastNotification";
import {
  getSubscriptionListSuccess,
  getSubscriptionListFailure,
  createUpdateSubscriptionListSuccess,
  createUpdateSubscriptionListFailure,
  getSingleSubscriptionSuccess,
  getSingleSubscriptionFailure,
  deleteSubscriptionSuccess,
  deleteSubscriptionFailure,
  getSubscribersListSuccess,
  getSubscribersListFailure
} from "../actions/SubscriptionActions";

function* fetchSubscriptionListAPI(action) {
  try {
    const response = yield api.postMethod("/admin/subscriptions_index", action.data);
    if (response.data.code === 200) {
      yield put(getSubscriptionListSuccess(response.data.data));
    } else {
      yield put(getSubscriptionListFailure(response.data.data));
      yield call(getErrorNotificationMessage, response.data.message);
    }
  } catch (error) {
    yield put(getSubscriptionListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateSubscriptionStart(action) {
  try {
    const response = yield api.postMethod("/admin/subscriptions_save", action.data);
    if (response.data.code === 200) {
      yield put(createUpdateSubscriptionListSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(createUpdateSubscriptionListFailure(response.data.data));
      yield call(getErrorNotificationMessage, response.data.message);
    }
  } catch (error) {
    yield put(createUpdateSubscriptionListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchSingleSubscriptionAPI(action) {
  try {
    const response = yield api.postMethod("/admin/subscriptions_view", action.data);
    if (response.data.code === 200) {
      yield put(getSingleSubscriptionSuccess(response.data.data));
    } else {
      yield put(getSingleSubscriptionFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(getSingleSubscriptionFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* deleteSubscriptionAPI(action) {
  try {
    const response = yield api.postMethod("", action.data);
    if (response.data.code === 200) {
      yield put(deleteSubscriptionSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(deleteSubscriptionFailure(response.data.data));
      yield call(getErrorNotificationMessage, response.data.message);
    }
  } catch (error) {
    yield put(deleteSubscriptionFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchSubscribersAPI(action) {
  try {
    const response = yield api.postMethod("/admin/subscribers_index", action.data);
    if (response.data.code === 200) {
      yield put(getSubscribersListSuccess(response.data.data));
    } else {
      yield put(getSubscribersListFailure(response.data.data));
      yield call(getErrorNotificationMessage, response.data.message);
    }
  } catch (error) {
    yield put(getSubscribersListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(GET_SUBSCRIPTION_LIST_START, fetchSubscriptionListAPI),
    yield takeLatest(CREATE_UPDATE_SUBSCRIPTION_START, updateSubscriptionStart),
    yield takeLatest(GET_SINGLE_SUBSCRIPTION_START, fetchSingleSubscriptionAPI),
    yield takeLatest(DELETE_SUBSCRIPTION_START, deleteSubscriptionAPI),
    yield takeLatest(GET_SUBSCRIBERS_LIST_START, fetchSubscribersAPI),
  ]);
}
