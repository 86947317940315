import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDButton from "components/MDButton";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import { connect } from "react-redux";
import { addStaticPagesStart } from "store/actions/LooksupActions";

const AddPage = (props) => {
  const addPageSchema = Yup.object().shape({
    title: Yup.string().required("Page Title is required *"),
    page_type: Yup.string().required("Page Type is required *"),
    description: Yup.string(),
  });

  const handleSubmit = (values) => {
    props.dispatch(addStaticPagesStart(values))
  };

  const [editorValue, setEditorValue] = useState(
    `<p>
      
    </p>`
  );

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Add Page</MDTypography>
        <Formik
          initialValues={{
            title: "",
            page_type: "Privacy",
            description: editorValue,
          }}
          validationSchema={addPageSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ touched, errors, isSubmitting, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3} mt={2}>
                <Grid item xs={12} sm={6} mt={2}>
                  <MDBox mb={0}>
                    <Field
                      type="text"
                      name="title"
                      render={({ field, form }) => (
                        <TextField
                          type="text"
                          label="Page Name"
                          variant="standard"
                          fullWidth
                          placeholder="Page Name"
                          InputLabelProps={{ shrink: true }}
                          error={Boolean(
                            form.errors.title && form.touched.title
                          )}
                          helperText={
                            form.touched.title && form.errors.title
                          }
                          onChange={(event) =>
                            setFieldValue("title", event.target.value)
                          }
                        />
                      )}
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MDBox mb={0}>
                    <MDBox mb={0} display="inline-block">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize"
                      >
                        Choose Pagetype
                      </MDTypography>
                    </MDBox>
                    <Autocomplete
                      options = {[
                        {label: 'Privacy', id: 'privacy'},
                        {label: 'Terms', id: 'terms'},
                        {label: 'About', id: 'about'},
                        {label: 'Contact', id: 'contact'},
                      ]}
                      onChange={(event, newValue) => {
                        setFieldValue("page_type",newValue.id);
                      }}
                      renderInput={(params) => (
                        <Field
                          type="text"
                          name="page_type"
                          render={({ field, form }) => (
                            <TextField
                              {...params}
                              variant="standard"
                              error={Boolean(
                                form.errors.page_type &&
                                  form.touched.page_type
                              )}
                              helperText={
                                form.touched.page_type &&
                                form.errors.page_type
                              }
                              
                            />
                          )}
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox mt={0}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <MDBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <MDTypography
                            component="label"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Description
                          </MDTypography>
                        </MDBox>
                        <MDEditor
                          value={editorValue}
                          onChange={setEditorValue}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox mt={3}>
                <Grid item xs={12} lg={12}>
                  <MDBox display="flex" justifyContent="center">
                    <MDButton variant="gradient" color="success" type="submit" disabled={props.addStaticPage.buttonDisable}>
                      {props.addStaticPage.loadingButtonContent != null ? props.addStaticPage.loadingButtonContent  : "Save"}
                    </MDButton>
                  </MDBox>
                </Grid>
              </MDBox>
            </Form>
          )}
        </Formik>
      </MDBox>
    </Card>
  );
};

const mapStateToPros = (state) => ({
  addStaticPage: state.looksup.addStaticPage,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}


export default connect(mapStateToPros, mapDispatchToProps)(AddPage);
