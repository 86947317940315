import React, { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import DropdownButton from "../components/DropdownButton";
import TablePagination from "@mui/material/TablePagination";
import ReactPaginate from "react-paginate";
import { getSubscribersListStart } from "store/actions/SubscriptionActions";

const WalletsList = (props) => {
  const { subscribers, subscription_id } = props;

  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;

  const handlePageClick = (data) => {
    let selected = data.selected;
    setCurrentPage(selected);
    props.dispatch(
      getSubscribersListStart({
        skip: selected * PER_PAGE,
        take: PER_PAGE,
        subscription_id: subscription_id,
      })
    );
  };

  useEffect(() => {
    props.dispatch(
      getSubscribersListStart({ subscription_id: subscription_id })
    );
  }, []);

  useEffect(() => {
    if (!props.subscribers.loading) {
      setPageCount(Math.ceil(props.subscribers.data.total / PER_PAGE));
    }
  }, [props.subscribers.data]);

  return (
    <>
      {subscribers.loading ? (
        <MDBox
          mt={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <MDTypography variant="h4" fontWeight="bold">
            loading
          </MDTypography>
        </MDBox>
      ) : Object.keys(subscribers.data).length > 0 ? (
        <MDBox my={3}>
          <TableContainer
            sx={{ maxHeight: "calc(100vh - 150px)", boxShadow: "none" }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <MDBox display="flex" flexDirection="column">
                      <MDTypography
                        variant="h6"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Name
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  <TableCell>
                    <MDBox display="flex" flexDirection="column">
                      <MDTypography
                        variant="h6"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Wallet address
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  <TableCell align="center">
                    <MDBox display="flex" flexDirection="column">
                      <MDTypography
                        variant="h6"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        created
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  {/* <TableCell align="center">
                          <MDBox display="flex" flexDirection="column">
                            <MDTypography
                              variant="h6"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              balance
                            </MDTypography>
                          </MDBox>
                        </TableCell> */}
                  <TableCell align="center">
                    <MDBox display="flex" flexDirection="column">
                      <MDTypography
                        variant="h6"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        actions
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                </TableRow>

                {subscribers.data.subscribers.map((wallet, index) => (
                  <>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          border:
                            index === props.subscribers.data.total - 1 && 0,
                        }}
                      >
                        <MDBox display="flex" flexDirection="column">
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                          >
                            {wallet.name}
                          </MDTypography>
                        </MDBox>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          border:
                            index === props.subscribers.data.total - 1 && 0,
                        }}
                      >
                        {/* <MDBox
                                display="flex"
                                alignItems="center"
                                width="max-content"
                              >
                                <MDBox
                                  component="img"
                                  src={profilePicture}
                                  alt={""}
                                  width={"50px"}
                                  borderRadius={"50%"}
                                  height="auto"
                                />{" "}
                                
                              </MDBox> */}
                        <MDBox display="flex" flexDirection="column">
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                          >
                            {wallet.wallet_address}
                          </MDTypography>
                        </MDBox>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          border:
                            index === props.subscribers.data.total - 1 && 0,
                        }}
                      >
                        <MDBox display="flex" flexDirection="column">
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                          >
                            {wallet.createdAt}
                          </MDTypography>
                        </MDBox>
                      </TableCell>
                      {/* <TableCell
                              align="center"
                              sx={{ border: index === walletData.length - 1 && 0 }}
                            >
                              <MDBadge
                                variant="contained"
                                color="success"
                                badgeContent={wallet.balance}
                                container
                              />
                            </TableCell> */}
                      <TableCell
                        align="center"
                        sx={{
                          border:
                            index === props.subscribers.data.total - 1 && 0,
                        }}
                      >
                        <DropdownButton
                          buttonTitle={"actions"}
                          id={wallet.xwallet_id}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <MDBox>
            <div className="list_of_nft_pagination_wrapper">
              <span className="flex items-center showing-text">
                Showing {currentPage * PER_PAGE + 1} -{" "}
                {currentPage == 0
                  ? PER_PAGE
                  : offset + PER_PAGE > subscribers.data.total
                  ? subscribers.data.total
                  : offset + PER_PAGE}{" "}
                of {subscribers.data.total}
              </span>
              <ReactPaginate
                previousLabel={
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4 fill-current"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                }
                nextLabel={
                  <svg
                    className="w-4 h-4 fill-current"
                    aria-hidden="true"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={currentPage}
                onPageChange={handlePageClick}
                containerClassName={"list_of_nft_pagination"}
                activeClassName={"active"}
              />
            </div>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox
          mt={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <MDTypography variant="h4" fontWeight="bold">
            No data found
          </MDTypography>
        </MDBox>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  subscribers: state.subscription.subscribers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WalletsList);
