// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import Sidenav from "pages/account/siteSettings/components/Sidenav";
import SiteSettings from "pages/account/siteSettings/components/sitesettings";
import MobileSettings from "pages/account/siteSettings/components/MobileSettings";
import SocialSettings from "pages/account/siteSettings/components/SocialSettings";
import Authentication from "pages/account/siteSettings/components/Authentication";
import Accounts from "pages/account/siteSettings/components/Accounts";
import Notifications from "pages/account/siteSettings/components/Notifications";
import Sessions from "pages/account/siteSettings/components/Sessions";
import DeleteAccount from "pages/account/siteSettings/components/DeleteAccount";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { getSettingsStart } from "store/actions/SettingsActions";

function Settings(props) {

  useEffect(() => {
    // if (!props.settings.loading && Object.keys(props.settings.data).length == 0) {
      props.dispatch(getSettingsStart());
    // }
  }, []);

  return (
    <>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SiteSettings />
                </Grid>
                {/* <Grid item xs={12}>
                  <MobileSettings />
                </Grid> */}
                <Grid item xs={12}>
                  <SocialSettings />
                </Grid>
                {/* <Grid item xs={12}>
                  <Authentication />
                </Grid>
                <Grid item xs={12}>
                  <Accounts />
                </Grid>
                <Grid item xs={12}>
                  <Notifications />
                </Grid>
                <Grid item xs={12}>
                  <Sessions />
                </Grid>
                <Grid item xs={12}>
                  <DeleteAccount />
                </Grid> */}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

const mapStateToPros = (state) => ({
  settings: state.settings.settings,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Settings);
