import {
  GET_SUBSCRIPTION_LIST_START,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  GET_SUBSCRIPTION_LIST_FAILURE,
  CREATE_UPDATE_SUBSCRIPTION_START,
  CREATE_UPDATE_SUBSCRIPTION_SUCCESS,
  CREATE_UPDATE_SUBSCRIPTION_FAILURE,
  GET_SINGLE_SUBSCRIPTION_START,
  GET_SINGLE_SUBSCRIPTION_SUCCESS,
  GET_SINGLE_SUBSCRIPTION_FAILURE,
  DELETE_SUBSCRIPTION_START,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAILURE,
  GET_SUBSCRIBERS_LIST_START,
  GET_SUBSCRIBERS_LIST_SUCCESS,
  GET_SUBSCRIBERS_LIST_FAILURE
} from "./ActionConstant";

export function getSubscriptionListStart(data) {
  return {
    type: GET_SUBSCRIPTION_LIST_START,
    data,
  };
}

export function getSubscriptionListSuccess(data) {
  return {
    type: GET_SUBSCRIPTION_LIST_SUCCESS,
    data,
  };
}

export function getSubscriptionListFailure(error) {
  return {
    type: GET_SUBSCRIPTION_LIST_FAILURE,
    error,
  };
}

export function createUpdateSubscriptionListStart(data) {
  return {
    type: CREATE_UPDATE_SUBSCRIPTION_START,
    data,
  };
}

export function createUpdateSubscriptionListSuccess(data) {
  return {
    type: CREATE_UPDATE_SUBSCRIPTION_SUCCESS,
    data,
  };
}

export function createUpdateSubscriptionListFailure(error) {
  return {
    type: CREATE_UPDATE_SUBSCRIPTION_FAILURE,
    error,
  };
}

export function getSingleSubscriptionStart(data) {
  return {
    type: GET_SINGLE_SUBSCRIPTION_START,
    data,
  };
}

export function getSingleSubscriptionSuccess(data) {
  return {
    type: GET_SINGLE_SUBSCRIPTION_SUCCESS,
    data,
  };
}

export function getSingleSubscriptionFailure(error) {
  return {
    type: GET_SINGLE_SUBSCRIPTION_FAILURE,
    error,
  };
}

export function deleteSubscriptionStart(data) {
  return {
    type: DELETE_SUBSCRIPTION_START,
    data,
  };
}

export function deleteSubscriptionSuccess(data) {
  return {
    type: DELETE_SUBSCRIPTION_SUCCESS,
    data,
  };
}

export function deleteSubscriptionFailure(error) {
  return {
    type: DELETE_SUBSCRIPTION_FAILURE,
    error,
  };
}

export function getSubscribersListStart(data) {
  return {
    type: GET_SUBSCRIBERS_LIST_START,
    data,
  };
}

export function getSubscribersListSuccess(data) {
  return {
    type: GET_SUBSCRIBERS_LIST_SUCCESS,
    data,
  };
}

export function getSubscribersListFailure(error) {
  return {
    type: GET_SUBSCRIBERS_LIST_FAILURE,
    error,
  };
}