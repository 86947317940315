// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import { resetPasswordStart } from "store/actions/UserAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import { connect } from "react-redux";

const ResetPassword = (props) => {


  const handleResetPassword = (values) => {

    const newData = { ...values};

    props.dispatch(resetPasswordStart(newData));
  };

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .required("Password is required *"),
    confirm_password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .required("Confirm Password is required *")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter new password.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={{
              password: "",
              confirm_password: "",
            }}
            validationSchema={resetPasswordSchema}
            onSubmit={(values) => handleResetPassword(values)}
          >
            {({ touched, errors, setFieldValue, isSubmitting }) => (
              <Form noValidate autoComplete="off">
                <MDBox mb={2}>
                  <Field
                    name="password"
                    type="password"
                    render={({ field, form }) => (
                      <TextField
                        type="password"
                        label="Password"
                        variant="standard"
                        fullWidth
                        placeholder="*****"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(
                          form.errors.password && form.touched.password
                        )}
                        helperText={
                          form.touched.password && form.errors.password
                        }
                        onChange={(event) =>
                          setFieldValue("password", event.target.value)
                        }
                      />
                    )}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <Field
                    name="confirm_password"
                    type="password"
                    render={({ field, form }) => (
                      <TextField
                        type="password"
                        label="Confirm Password"
                        variant="standard"
                        fullWidth
                        placeholder="*****"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(
                          form.errors.confirm_password &&
                            form.touched.confirm_password
                        )}
                        helperText={
                          form.touched.confirm_password &&
                          form.errors.confirm_password
                        }
                        onChange={(event) =>
                          setFieldValue("confirm_password", event.target.value)
                        }
                      />
                    )}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                    disabled={props.resetPassword.buttonDisable}
                  >
                    {props.resetPassword.loadingButtonContent !== null
                      ? props.resetPassword.loadingButtonContent
                      : "Reset password"}
                  </MDButton>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Already have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign In
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </Form>
            )}
          </Formik>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};

const mapStateToPros = (state) => ({
  resetPassword: state.users.resetPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ResetPassword);
