import { Card, Grid, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useState, useEffect } from "react";
import WalletCard from "assets/images/walletcard/card.png";
import BNB from "assets/images/walletcard/bnb.png";
import BUSD from "assets/images/walletcard/busd.png";
import BUSDX from "assets/images/walletcard/busdx.png";
import BUSDXName from "assets/images/walletcard/cardlogos/busdx.png";
import BUSDXLogo from "assets/images/walletcard/cardlogos/busdx_logo.png";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import TablePagination from "@mui/material/TablePagination";
import { Link, useParams } from "react-router-dom";
import MDButton from "components/MDButton";
import { viewWalletStart  , getSingleTransactionStart} from "store/actions/WalletActions";
import { connect } from "react-redux";

const ViewWallet = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const { xwallet_id } = useParams();

  const { viewWallet } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    props.dispatch(viewWalletStart({ xwallet_id: xwallet_id }));
    props.dispatch(getSingleTransactionStart({ xwallet_id: xwallet_id }))
  }, []);

  const transctionCount = Array.from(Array(30).keys());

  return (
    <MDBox my={3}>
      <Card>
        <Grid container spacing={3} p={3}>
          {viewWallet.loading ? (
            "loading"
          ) : (
            <>
              {viewWallet.data.wallet && (
                <>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className="wallet-card-wrapper">
                      <div className="wallet-card">
                        <div className="card-img">
                          <MDBox
                            component="img"
                            src={WalletCard}
                            alt="wallet"
                            width="100%"
                            display="block"
                          />
                          <div className="card-details">
                            <div className="card-logo-images">
                              <MDBox
                                component="img"
                                src={BUSDXLogo}
                                alt="wallet"
                                width="3vw"
                                display="block"
                              />
                              <MDBox
                                component="img"
                                src={BUSDXName}
                                alt="wallet"
                                width="10vw"
                                display="block"
                              />
                            </div>
                            <div className="card-number-wrapper">
                              <MDTypography
                                component="h4"
                                variant="h4"
                                fontWeight="bold"
                                color="white"
                                textTransform="capitalize"
                                textAlign="center"
                              >
                                XXXX
                              </MDTypography>
                              <MDTypography
                                component="h4"
                                variant="h4"
                                fontWeight="bold"
                                color="white"
                                textTransform="capitalize"
                                textAlign="center"
                              >
                                XXXX
                              </MDTypography>
                              <MDTypography
                                component="h4"
                                variant="h4"
                                fontWeight="bold"
                                color="white"
                                textTransform="capitalize"
                                textAlign="center"
                              >
                                XXXX
                              </MDTypography>
                              <MDTypography
                                component="h4"
                                variant="h4"
                                fontWeight="bold"
                                color="white"
                                textTransform="capitalize"
                                textAlign="center"
                              >
                                {viewWallet.data.wallet.last_four}
                              </MDTypography>
                            </div>
                            <div className="card-user-details">
                              <div className="card-name">
                                <MDTypography
                                  component="h4"
                                  variant="h4"
                                  fontWeight="bold"
                                  color="white"
                                  textTransform="capitalize"
                                  textAlign="center"
                                >
                                  {viewWallet.data.wallet.name}
                                </MDTypography>
                              </div>
                              <div className="card-expiry">
                                <MDTypography
                                  component="p"
                                  variant="caption"
                                  fontWeight="bold"
                                  color="white"
                                  textTransform="capitalize"
                                  textAlign="center"
                                >
                                  Wallet expiry
                                </MDTypography>
                                <MDTypography
                                  component="h4"
                                  variant="h4"
                                  fontWeight="bold"
                                  color="white"
                                  textTransform="capitalize"
                                  textAlign="center"
                                >
                                  {viewWallet.data.wallet.xwallet_expiry}
                                </MDTypography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </>
              )}
              {viewWallet.data.balances && (
                <Grid item xs={12} md={6} lg={6}>
                  <div className="wallet-card-balance-wrapper">
                    {/* <div className="wallet-balance-overlay"></div> */}
                    <div className="wallet-balance">
                      <Grid container spacing={0}>
                        <Grid xs={12}>
                          <MDBox mt={2}>
                            <MDTypography
                              component="h4"
                              variant="h4"
                              fontWeight="bold"
                              color="text"
                              textTransform="capitalize"
                              textAlign="center"
                            >
                              Balance
                            </MDTypography>{" "}
                          </MDBox>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <MDBox
                                    display="flex"
                                    alignItems="center"
                                    gap="1rem"
                                  >
                                    <MDBox
                                      component="img"
                                      src={BNB}
                                      alt="wallet"
                                      width="3vw"
                                      display="block"
                                    />
                                    <MDTypography
                                      component="h6"
                                      variant="h6"
                                      fontWeight="bold"
                                      color="text"
                                      textTransform="capitalize"
                                    >
                                      BNB
                                    </MDTypography>
                                  </MDBox>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  alignItems="center"
                                  display="flex"
                                >
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    {
                                      viewWallet.data.balances
                                        .bnb_balance_formatted
                                    }
                                  </MDTypography>
                                </Grid>
                                {/* <Grid
                                  item
                                  xs={4}
                                  alignItems="center"
                                  display="flex"
                                >
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    $2300.24
                                  </MDTypography>
                                </Grid> */}
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={4}>
                                  <MDBox
                                    display="flex"
                                    alignItems="center"
                                    gap="1rem"
                                  >
                                    <MDBox
                                      component="img"
                                      src={BUSD}
                                      alt="wallet"
                                      width="3vw"
                                      display="block"
                                    />
                                    <MDTypography
                                      component="h6"
                                      variant="h6"
                                      fontWeight="bold"
                                      color="text"
                                      textTransform="capitalize"
                                    >
                                      BUSD
                                    </MDTypography>
                                  </MDBox>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  alignItems="center"
                                  display="flex"
                                >
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    {
                                      viewWallet.data.balances
                                        .busd_balance_formatted
                                    }
                                  </MDTypography>
                                </Grid>
                                {/* <Grid
                                  item
                                  xs={4}
                                  alignItems="center"
                                  display="flex"
                                >
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    $430.24
                                  </MDTypography>
                                </Grid> */}
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={4}>
                                  <MDBox
                                    display="flex"
                                    alignItems="center"
                                    gap="1rem"
                                  >
                                    <MDBox
                                      component="img"
                                      src={BUSDX}
                                      alt="wallet"
                                      width="3vw"
                                      display="block"
                                    />
                                    <MDTypography
                                      component="h6"
                                      variant="h6"
                                      fontWeight="bold"
                                      color="text"
                                      textTransform="capitalize"
                                    >
                                      BUSDX
                                    </MDTypography>
                                  </MDBox>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  alignItems="center"
                                  display="flex"
                                >
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    {
                                      viewWallet.data.balances
                                        .busdx_balance_formatted
                                    }
                                  </MDTypography>
                                </Grid>
                                {/* <Grid
                                  item
                                  xs={4}
                                  alignItems="center"
                                  display="flex"
                                >
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    $230.24
                                  </MDTypography>
                                </Grid> */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2} mt={1}>
                            {viewWallet.data.wallet && (
                              <>
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={6} md={6} lg={4}>
                                      <MDTypography
                                        component="h6"
                                        variant="h6"
                                        fontWeight="bold"
                                        color="text"
                                        textTransform="capitalize"
                                      >
                                        Wallet Address :
                                      </MDTypography>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={8}>
                                      <Tooltip
                                        title="0x4be4f06144F685A43cEF41EFa7f30D8359dac913"
                                        placement="top"
                                      >
                                        <MDTypography
                                          component="h6"
                                          variant="h6"
                                          fontWeight="bold"
                                          color="text"
                                          textTransform="capitalize"
                                          noWrap
                                        >
                                          {
                                            viewWallet.data.wallet
                                              .wallet_address
                                          }
                                        </MDTypography>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </>
                            )}

                            {/* <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={6} md={4}>
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    Device
                                  </MDTypography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    Web
                                  </MDTypography>
                                </Grid>
                              </Grid>
                            </Grid> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              )}
              {viewWallet.data.subscription && (
                <Grid item xs={12} md={12} lg={12}>
                  <div className="wallet-card-balance-wrapper">
                    {/* <div className="wallet-balance-overlay"></div> */}
                    <div className="wallet-balance">
                      <Grid container spacing={0}>
                        <Grid xs={12}>
                          <MDBox my={3} mt={0}>
                            <MDTypography
                              component="h4"
                              variant="h4"
                              fontWeight="bold"
                              color="text"
                              textTransform="capitalize"
                              textAlign="center"
                            >
                              Subscription Details
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} lg={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    Active Plan :
                                  </MDTypography>
                                </Grid>
                                <Grid item xs={6}>
                                  <MDBox display="flex" flexDirection="column">
                                    <MDBadge
                                      variant="gradient"
                                      color="primary"
                                      badgeContent={viewWallet.data.subscription.title}
                                      container
                                      size="md"
                                    />
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    BUSD limit:
                                  </MDTypography>
                                </Grid>
                                <Grid item xs={6}>
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                   {viewWallet.data.subscription.busd_limit_formatted}
                                  </MDTypography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    Plan type :
                                  </MDTypography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  alignItems="center"
                                  display="flex"
                                >
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                   {viewWallet.data.subscription.plan_formatted}
                                  </MDTypography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    BUSDX limit :
                                  </MDTypography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  alignItems="center"
                                  display="flex"
                                >
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    {viewWallet.data.subscription.busdx_limit_formatted}
                                  </MDTypography>
                                </Grid>
                              </Grid>
                            </Grid>
                           
                            {/* <Grid item xs={12} lg={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    User Spent :
                                  </MDTypography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  alignItems="center"
                                  display="flex"
                                >
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    $15,000 BUSD
                                  </MDTypography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    Available balance :
                                  </MDTypography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  alignItems="center"
                                  display="flex"
                                >
                                  <MDTypography
                                    component="h6"
                                    variant="h6"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    $10,000 BUSD
                                  </MDTypography>
                                </Grid>
                              </Grid>
                            </Grid> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12} md={12} lg={12} hidden>
            <MDBox mt={0}>
              <MDTypography
                component="h4"
                variant="h4"
                fontWeight="bold"
                color="text"
                textTransform="capitalize"
                textAlign="center"
              >
                Transaction Details
              </MDTypography>
              <div className="wallet-transaction-list-wrapper">
                {transctionCount
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((index) => (
                    <div className="wallet-transaction-list" key={index}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <MDBox
                                display="flex"
                                alignItems="center"
                                gap="1rem"
                              >
                                <MDBox
                                  component="img"
                                  src={BNB}
                                  alt="wallet"
                                  width="2vw"
                                  display="block"
                                />
                                <MDBox display="grid" gap="0.5rem">
                                  <Tooltip
                                    title="0x9c12b4d8427370ec832495879e838aba4b898ae4fa7270ac64e0d8fe49fb4e5c"
                                    placement="top"
                                  >
                                    <MDTypography
                                      component="h6"
                                      variant="h6"
                                      fontWeight="bold"
                                      color="text"
                                      textTransform="capitalize"
                                      noWrap
                                    >
                                      0x9c12b4d8427370ec832495879e838aba4b898ae4fa7270ac64e0d8fe49fb4e5c
                                    </MDTypography>
                                  </Tooltip>

                                  <MDBox
                                    display="flex"
                                    flexDirection="row"
                                    gap="0.5rem"
                                    style={{ width: "100%" }}
                                  >
                                    <MDTypography
                                      component="h6"
                                      variant="h6"
                                      fontWeight="bold"
                                      color="text"
                                      textTransform="capitalize"
                                    >
                                      0.02 BNB
                                    </MDTypography>
                                    <MDTypography
                                      component="h6"
                                      variant="h6"
                                      fontWeight="bold"
                                      color="text"
                                      textTransform="capitalize"
                                    >
                                      $20.78
                                    </MDTypography>
                                  </MDBox>
                                </MDBox>
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              alignItems="center"
                              display="flex"
                              justifyContent="center"
                            >
                              <MDBadge
                                variant="gradient"
                                color="success"
                                badgeContent={"Received"}
                                container
                                size="md"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              alignItems="center"
                              display="flex"
                              justifyContent="center"
                            >
                              <MDButton
                                component={Link}
                                to="/view_transaction"
                                variant="gradient"
                                color={"primary"}
                                size="small"
                              >
                                View
                              </MDButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
              </div>
              <MDBox>
                <TablePagination
                  rowsPerPageOptions={[12]}
                  component="div"
                  count={transctionCount.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{ color: "#fff", fill: "#fff" }}
                />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
};

const mapStateToPros = (state) => ({
  viewWallet: state.wallet.viewWallet,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ViewWallet);
