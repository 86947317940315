// @mui material components
import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "store/actions/UserAction";
import { useEffect } from "react";
import RecentTransaction from "pages/recentTransactions";
import {fetchDashboardStart} from "store/actions/LooksupActions"

const Dashboard = (props) => {

  useEffect(() => {
    props.dispatch(fetchDashboardStart());
  }, []);
  console.log(props.dashboard.data.widgets);
  return (
    <>
      <MDBox py={3}>
        {props.dashboard.loading ? '' : 
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Wallet"
                  count={props.dashboard.data.widgets.total_wallets}
                  percentage={{
                    color: "success",
                    // amount: "+55%",
                    label: "Wallet Trasactions",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Today's Wallet"
                  count={props.dashboard.data.widgets.today_wallets}
                  percentage={{
                    color: "success",
                    // amount: "+3%",
                    label: "Wallet Trasactions",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Total"
                  count={props.dashboard.data.widgets.total_transactions}
                  percentage={{
                    color: "success",
                    // amount: "+1%",
                    label: "Total Trasactions",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Today"
                  count={props.dashboard.data.widgets.today_transactions}
                  percentage={{
                    color: "success",
                    // amount: "",
                    label: "Today Trasactions",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        }
        <MDBox my={3}>
          <RecentTransaction />
        </MDBox>
      </MDBox>
    </>
  );
};

const mapStateToPros = (state) => ({
  dashboard: state.looksup.dashboard,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Dashboard);
