import React from "react";
import NotFoundImage from "assets/images/404.png";
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";
import MDTypography from "components/MDTypography";

const index = () => {

  let accessToken =
      localStorage.getItem("accessToken") !== "" &&
      localStorage.getItem("accessToken") !== null &&
      localStorage.getItem("accessToken") !== undefined
        ? localStorage.getItem("accessToken")
        : "";

  return (
    <>
      <MDBox
        minHeight="100vh"
        sx={{ overflow: "hidden" }}
        pb={3}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection="column"
      >
        <img src={NotFoundImage} />
        <MDBox mt={3} mb={1} textAlign="center">
          <MDTypography
            component={Link}
            to={accessToken != "" ? "/dashboard" : "/"}
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            Back to home
          </MDTypography>
        </MDBox>
      </MDBox>
    </>
  );
};

export default index;
