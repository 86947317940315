import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import PageReducer from "./PageReducer";
import SettingsReducer from "./SettingsReducer";
import LooksupReducer from "./LooksupReducer";
import WalletReducer from "./WalletReducer";
import SubscriptionReducer from "./SubscriptionReducer";

export default combineReducers({
  users: UserReducer,
  page: PageReducer,
  settings : SettingsReducer,
  looksup : LooksupReducer,
  wallet : WalletReducer,
  subscription : SubscriptionReducer
});
