import React, { useMemo, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TransactionImage from "assets/images/transaction-history.png";
import MDBadge from "components/MDBadge";
import { getTransactionHistoryStart } from "store/actions/WalletActions"
import { connect } from "react-redux";

const TransactionHistoryTable = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.dispatch(getTransactionHistoryStart({skip:newPage*rowsPerPage}))
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    props.dispatch(getTransactionHistoryStart())
  }, [])

  return (
    <>
      {props.transactionHistory.loading ? (
        "Loading..."
      ) : (
      <MDBox>
        {props.transactionHistory.data.transactions ?
        <>
        <TableContainer
          sx={{ height: "calc(100vh - 200px)", boxShadow: "none" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <MDBox
                component="tr"
                width="max-content"
                display="block"
                mb={1.5}
              >
                <MDTypography variant="h6" component="td">
                  Transaction History
                </MDTypography>
              </MDBox>
            </TableHead>
            <TableBody>
              {props.transactionHistory.data.transactions
                .map((transaction) => (
                  <>
                    <TableRow>
                      <TableCell>
                        <MDBox
                          display="flex"
                          flexDirection="row"
                          gap={"1rem"}
                          alignItems={"center"}
                        >
                          <MDBox
                            component="img"
                            src={TransactionImage}
                            alt={""}
                            width={"50px"}
                            height="auto"
                          />{" "}
                          <MDBox display="flex" flexDirection="column">
                            <MDTypography
                              variant="p"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {transaction.xwallet_transaction_id}
                            </MDTypography>
                            <MDTypography
                              variant="p"
                              fontWeight="regular"
                              textTransform="capitalize"
                              color={"success"}
                            >
                              {transaction.amount_formatted}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </TableCell>
                      <TableCell>
                        <MDBox display="flex" flexDirection="column">
                          <MDTypography
                            variant="p"
                            fontWeight="regular"
                            textTransform="capitalize"
                          >
                            To Address
                            <MDTypography
                              variant="p"
                              fontWeight="regular"
                              textTransform="capitalize"
                              color={"error"}
                            >
                              {" "}
                              {transaction.to_address}
                            </MDTypography>
                          </MDTypography>
                        </MDBox>
                      </TableCell>
                      <TableCell>
                        <MDBox display="flex" flexDirection="column">
                          <MDTypography
                            variant="p"
                            fontWeight="regular"
                            textTransform="capitalize"
                          >
                            Date
                            <MDTypography
                              variant="p"
                              fontWeight="regular"
                              textTransform="capitalize"
                              color={"error"}
                            >
                              {" "}
                              {transaction.start_date}
                            </MDTypography>
                          </MDTypography>
                        </MDBox>
                      </TableCell>
                      <TableCell>
                        <MDBox display="flex" flexDirection="column">
                          <MDBadge
                            variant="contained"
                            color="success"
                            badgeContent={transaction.balance}
                            container
                          />
                        </MDBox>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <MDBox>
          <TablePagination
            rowsPerPageOptions={[12]}
            component="div"
            count={props.transactionHistory.data.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ color: "#fff", fill: "#fff" }}
          />
        </MDBox>
        </>
        : ''}
      </MDBox>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  transactionHistory: state.wallet.transactionHistory,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TransactionHistoryTable);
