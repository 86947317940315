import {
  GET_WALLET_LIST_START,
  GET_WALLET_LIST_SUCCESS,
  GET_WALLET_LIST_FAILURE,
  VIEW_WALLET_START,
  VIEW_WALLET_SUCCESS,
  VIEW_WALLET_FAILURE,
  GET_TRANSACTION_HISTORY_START,
  GET_TRANSACTION_HISTORY_SUCCESS,
  GET_TRANSACTION_HISTORY_FAILURE,
  GET_RECENT_TRANSACTION_START,
  GET_RECENT_TRANSACTION_SUCCESS,
  GET_RECENT_TRANSACTION_FAILURE,
  GET_SINGLE_TRANSACTION_START,
  GET_SINGLE_TRANSACTION_SUCCESS,
  GET_SINGLE_TRANSACTION_FAILURE
} from "./ActionConstant";

export function getWalletListStart(data) {
  return {
    type: GET_WALLET_LIST_START,
    data,
  };
}

export function getWalletListSuccess(data) {
  return {
    type: GET_WALLET_LIST_SUCCESS,
    data,
  };
}

export function getWalletListFailure(error) {
  return {
    type: GET_WALLET_LIST_FAILURE,
    error,
  };
}

export function viewWalletStart(data) {
  return {
    type: VIEW_WALLET_START,
    data,
  };
}

export function viewWalletSuccess(data) {
  return {
    type: VIEW_WALLET_SUCCESS,
    data,
  };
}

export function viewWalletFailure(error) {
  return {
    type: VIEW_WALLET_FAILURE,
    error,
  };
}

export function getTransactionHistoryStart(data) {
  return {
    type: GET_TRANSACTION_HISTORY_START,
    data,
  };
}

export function getTransactionHistorySuccess(data) {
  return {
    type: GET_TRANSACTION_HISTORY_SUCCESS,
    data,
  };
}

export function getTransactionHistoryFailure(error) {
  return {
    type: GET_TRANSACTION_HISTORY_FAILURE,
    error,
  };
}

export function getRecentTransactionsStart(data) {
  return {
    type: GET_RECENT_TRANSACTION_START,
    data,
  };
}

export function getRecentTransactionsSuccess(data) {
  return {
    type: GET_RECENT_TRANSACTION_SUCCESS,
    data,
  };
}

export function getRecentTransactionsFailure(error) {
  return {
    type: GET_RECENT_TRANSACTION_FAILURE,
    error,
  };
}

export function getSingleTransactionStart(data) {
  return {
    type: GET_SINGLE_TRANSACTION_START,
    data,
  };
}

export function getSingleTransactionSuccess(data) {
  return {
    type: GET_SINGLE_TRANSACTION_SUCCESS,
    data,
  };
}

export function getSingleTransactionFailure(error) {
  return {
    type: GET_SINGLE_TRANSACTION_FAILURE,
    error,
  };
}

