import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_USER_DETAILS_START,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_START,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  FETCH_OTHER_USER_DETAILS_START,
  FETCH_OTHER_USER_DETAILS_SUCCESS,
  FETCH_OTHER_USER_DETAILS_FAILURE,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  DELETE_ACCOUNT_START,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  USERNAME_VALIDATION_START,
  USERNAME_VALIDATION_SUCCESS,
  USERNAME_VALIDATION_FAILURE,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE
} from "../actions/ActionConstant";

const initialState = {
  profile: {
    data: {},
    loading: false,
    error: false,
  },
  otherProfile: {
    data: {},
    loading: true,
    error: false,
  },
  loginInputData: {
    data: {},
    loading: false,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  updateUserDetails: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  registerInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  forgotPasswordInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  deleteAccount: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  validationInputData: {
    data: {},
    loading: true,
    error: false,
    isValid: false,
    isInValid: false,
  },
  resetPasswordInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  logout: {
    data: {},
    loading: false,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  changePassword : {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        loginInputData: {
          data: {
            ...action.data,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
        loginInputData: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "Logged in successfully",
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loginInputData: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case FETCH_USER_DETAILS_START:
      return {
        ...state,
        profile: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        profile: {
          data: {},
          loading: false,
          error: true,
        },
      };
    case UPDATE_USER_DETAILS_START:
      return {
        ...state,
        updateUserDetails: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Updating...",
          buttonDisable: true,
        },
      };
    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        updateUserDetails: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        updateUserDetails: {
          data: {},
          loading: true,
          error: action.error,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case FETCH_OTHER_USER_DETAILS_START:
      return {
        ...state,
        otherProfile: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_OTHER_USER_DETAILS_SUCCESS:
      return {
        ...state,
        otherProfile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_OTHER_USER_DETAILS_FAILURE:
      return {
        ...state,
        otherProfile: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    case REGISTER_START:
      return {
        ...state,
        registerInputData: {
          data: {
            ...action.data,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
        },
        registerInputData: {
          data: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        registerInputData: {
          data: {},
          loading: false,
          error: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FORGOT_PASSWORD_START:
      return {
        ...state,
        forgotPasswordInputData: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordInputData: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: "Email sent successfully",
        },
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordInputData: {
          data: {},
          loading: true,
          error: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case DELETE_ACCOUNT_START:
      return {
        ...state,
        deleteAccount: {
          data: {},
          loading: true,
          error: {},
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteAccount: {
          data: action.data,
          loading: true,
          error: {},
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteAccount: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case RESET_PASSWORD_START:
      return {
        ...state,
        resetPasswordInputData: {
          ...state.resetPasswordInputData,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
        resetPasswordInputData: {
          ...state.resetPasswordInputData,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordInputData: {
          ...state.resetPasswordInputData,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case USERNAME_VALIDATION_START:
      return {
        ...state,
        validationInputData: {
          data: {
            ...action.data,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case USERNAME_VALIDATION_SUCCESS:
      return {
        ...state,
        validationInputData: {
          data: {},
          loading: false,
          error: false,
          isValid: true,
          isInValid: false,
        },
      };
    case USERNAME_VALIDATION_FAILURE:
      return {
        ...state,
        validationInputData: {
          data: {},
          loading: false,
          error: action.data,
          isInValid: true,
          isValid: false,
        },
      };

    case DELETE_ACCOUNT_START:
      return {
        ...state,
        deleteAccount: {
          data: {},
          loading: true,
          error: {},
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteAccount: {
          data: action.data,
          loading: true,
          error: {},
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteAccount: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case LOGOUT_START:
      return {
        ...state,
        logout: {
          data: {},
          loading: true,
          error: {},
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        logout: {
          data: action.data,
          loading: false,
          error: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        logout: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

      case CHANGE_PASSWORD_START:
      return {
        ...state,
        changePassword: {
          data: {},
          loading: true,
          error: {},
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          data: action.data,
          loading: false,
          error: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePassword: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
